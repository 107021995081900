import { useState, useEffect } from "react";
import engleLeft from "../../../../assets/images/angle-left.svg";
import engleRight from "../../../../assets/images/angle-right.svg";
import searchIcon from "../../../../assets/images/search.svg";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RejectModal from "../../../../components/modal/RejectModal";
import AcceptModal from "../../../../components/modal/AcceptModal";
import { ordersList, updateOrder } from "../../../../utils/api";

export default function CancelledOrders() {
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [searchQuery, setSearchQuery] = useState(""); // State for search query

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


    const openRejectModal = () => setIsRejectModalOpen(true);
    const openAcceptModal = () => setIsAcceptModalOpen(true);
    const closeModal = () => {
        setIsRejectModalOpen(false);
        setIsAcceptModalOpen(false);
        setSelectedOrderId(null);
    };

    const fetchOrders = async () => {
        try {
            const response = await ordersList();
            const pendingOrders = response.data.filter(order => order.status === "usercancelled");
            setOrders(pendingOrders);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching orders:", error);
            setLoading(false);
        }
    };

    const updateOrderStatus = async (orderId, status) => {
        try {
            const response = await updateOrder(orderId, { status });
            if (response.status === 200) {
                fetchOrders();
            }
        } catch (error) {
            console.error(`Error updating order status to ${status}:`, error);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    const filteredOrders = orders.filter(order =>
        order.orderNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredOrders.length / pageSize);
    // const paginatedOrders = filteredOrders.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    const sortedOrders = filteredOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    const paginatedOrders = sortedOrders.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    

    const goToPreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const goToNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
    const goToPage = (page) => setCurrentPage(page);

    const handleAccept = (orderId) => {
        setSelectedOrderId(orderId);
        openAcceptModal();
    };

    const handleReject = (orderId) => {
        setSelectedOrderId(orderId);
        openRejectModal();
    };

    const handleAcceptConfirm = () => {
        toast.success("Order has been successfully updated.");
        updateOrderStatus(selectedOrderId, "Fulfilled");
        closeModal();
    };

    const handleRejectConfirm = () => {
        toast.success("Order has been successfully updated.");
        updateOrderStatus(selectedOrderId, "Cancelled");
        closeModal();
    };

    return (
        <>
            <ToastContainer />
            {isRejectModalOpen && <RejectModal closeModal={closeModal} onConfirm={handleRejectConfirm} />}
            {isAcceptModalOpen && <AcceptModal closeModal={closeModal} onConfirm={handleAcceptConfirm} />}
            <div className="table_layout_wrapper">
                <div className="container">
                    <div className="table_title">
                        <h2>Cancelled Orders</h2>
                        <div className="table_search">
                            <form>
                                <ul className="list-none flex">
                                    <li>
                                        <div className="input_with_calendar">
                                            <input
                                                placeholder="Search by Order ID"
                                                type="text"
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                            <img src={searchIcon} alt="search" className="searchicon" />
                                        </div>
                                    </li>
                                </ul>
                            </form>
                            <div className="pagination_wrap">
                                <h6>{filteredOrders.length} Items</h6>
                                <ul className="list-none flex">
                                    <li>
                                        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                                            <img src={engleLeft} alt="angle-left" />
                                        </button>
                                    </li>
                                    {[...Array(totalPages).keys()].map(page => (
                                        <li key={page}>
                                            <button
                                                onClick={() => goToPage(page + 1)}
                                                className={currentPage === page + 1 ? "active" : ""}
                                            >
                                                {page + 1}
                                            </button>
                                        </li>
                                    ))}
                                    <li>
                                        <button onClick={goToNextPage} disabled={currentPage === totalPages}>
                                            <img src={engleRight} alt="angle-right" />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="table_layout">
                        <div className="table_layout_responsive bigtable">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Order</th>
                                        <th>Order Date</th>
                                        <th>Cancel Date</th>
                                        <th>Email</th>
                                        <th>Shipping Date</th>
                                        <th>Approx. Date of Arrival</th>
                                        <th style={{ width: "296px" }}>Address</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedOrders.length > 0 ? (
                                        paginatedOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((order, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {order.orderNumber} <span style={{ textTransform: "capitalize" }}>{order.customerName}</span>
                                                </td>
                                                <td>
                                                    {new Date(order.createdAt).toLocaleDateString("en-US", {
                                                   timeZone: userTimeZone,
                                                    year: "numeric",
                                                    month: "short", // "long", "short", or "numeric"
                                                    day: "2-digit"
                                                    })}
                                                </td>
                                                <td>
                                                    {new Date(order.createdAt).toLocaleDateString("en-US", {
                                                        timeZone: userTimeZone,
                                                        year: "numeric",
                                                        month: "short", // "long", "short", or "numeric"
                                                        day: "2-digit"
                                                    })}
                                                </td>
                                                <td>
                                                    <Link className="innerLink" to={`mailto:${order.email}`}>
                                                        {order.email}
                                                    </Link>
                                                </td>
                                                <td>{new Date(order.shippingDate).toLocaleDateString("en-US", {
                                               timeZone: userTimeZone,
                                                year: "numeric",
                                                month: "short", // "long", "short", or "numeric"
                                                day: "2-digit"
                                              })}</td>
                                                    <td>{new Date(order.arrivalDate).toLocaleDateString("en-US", {
                                               timeZone: userTimeZone,
                                                year: "numeric",
                                                month: "short", // "long", "short", or "numeric"
                                                day: "2-digit"
                                              })}</td>
                                                <td>{order.address}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="7" style={{ textAlign: "center" }} className="no-data">
                                                No cancelled orders available.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
