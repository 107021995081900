import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./schedule.scss";
import { createAppointment, getAvailability, appointmentsList } from "../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ScheduleModal = ({ closeModal }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableSlots, setAvailableSlots] = useState([]);
  const [bookedSlots, setBookedSlots] = useState([]); // Store booked slot times
  const [selectedSlot, setSelectedSlot] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


  // Fetch available time slots for all dates
  const fetchAvailability = async () => {
    try {
      const response = await getAvailability();
      if (response.data) {
        const slotsByDate = response.data.reduce((acc, { date, timeSlots }) => {
          const formattedDate = new Date(date).toLocaleDateString("en-US", {
            // timeZone: userTimeZone,
            year: "numeric",
            month: "short", // "long", "short", or "numeric"
            day: "2-digit"
          })
          acc[formattedDate] = timeSlots;
          return acc;
        }, {});
        setAvailableSlots(slotsByDate);

        // console.log(
        //   Object.keys(slotsByDate)
        //   .filter((date) => new Date(date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)) // Include today
        //   .sort((a, b) => new Date(a) - new Date(b)) // Sort dates in ascending order
        //   .find((date) => {
        //     const availableTimes = slotsByDate[date];
        //     return (
        //       availableTimes.filter(
        //         (slot) => !bookedSlots[date]?.includes(slot.time)
        //       ).length > 0
        //     );
        //   }),
        //   "qw"
        // );
        


          // Find the first date with available slots that are not booked
          const firstAvailableDate = Object.keys(slotsByDate)
          .filter((date) => new Date(date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)) // Include today
          .sort((a, b) => new Date(a) - new Date(b)) // Sort dates in ascending order
          .find((date) => {
            const availableTimes = slotsByDate[date];
            return (
              availableTimes.filter(
                (slot) => !bookedSlots[date]?.includes(slot.time)
              ).length > 0
            );
          })
          

          // alert(firstAvailableDate)
  
        if (firstAvailableDate) {
            setSelectedDate(new Date(firstAvailableDate)); // Set the first available date
        }

      } else {
        setAvailableSlots({});
      }
    } catch (err) {
      console.error("Error fetching availability:", err);
      setError("Failed to fetch available slots. Please try again.");
    }
  };

  // Fetch already booked slots for all dates
  const fetchBookedSlots = async () => {
    try {
      const response = await appointmentsList();
      if (response.data) {
        const groupedByDate = response.data.reduce((acc, { date, time }) => {
          const formattedDate = new Date(date).toLocaleDateString("en-US", {
            // timeZone: userTimeZone,
            year: "numeric",
            month: "short", // "long", "short", or "numeric"
            day: "2-digit"
          });
          if (!acc[formattedDate]) {
            acc[formattedDate] = [];
          }
          acc[formattedDate].push(time); // Store booked times
          return acc;
        }, {});
        setBookedSlots(groupedByDate);
      } else {
        setBookedSlots({});
      }
    } catch (err) {
      console.error("Error fetching booked slots:", err);
      setBookedSlots({});
    }
  };

  

  useEffect(() => {
    // fetchAvailability();
    fetchBookedSlots();
  }, []);

  useEffect(() => {
    fetchAvailability();
    // fetchBookedSlots();
  }, [bookedSlots]);

  const handleScheduleAppointment = async (e) => {
    e.preventDefault();
    let isValid = true;


    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if(!selectedSlot && !phone && !email) {
      toast.error("Please fill all required fields.")
      return;
    }

    if (!email) {
      setEmailError("Email address is required.");
      isValid = false;
      return;
    }

    if(!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.")
      return;
    }

    setPhoneError("");
    setEmailError("");

    if (!selectedSlot) {
      // toast.error("No slots available. Please select a different time.");
      availableSlots[formattedSelectedDate]  ? 
      toast.error("No slot selected. Please select a slot.")

      :
      toast.error("No slots available. Please select a different date.")

      isValid = false;
      return;
    }

    if (!phone) {
      setPhoneError("Phone number is required.");
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    setLoading(true);
    try {

      const formattedDate = selectedDate

      console.log(formattedDate,"weedspp",selectedDate)
      
      const appointmentData = {
        email,
        phone,
        date: formattedDate,
        time: selectedSlot,
      };

      const response = await createAppointment(appointmentData);
      if (response.status === 201) {
        toast.success("Appointment scheduled successfully.");
        setLoading(false);
        setError(""); // Clear error if any

        setTimeout(() => {
          closeModal();
        }, 2000);
      }
    } catch (err) {
      console.error("Error creating appointment:", err);
      setError("Error creating appointment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const formattedSelectedDate = selectedDate.toLocaleDateString("en-US", {
    // timeZone: userTimeZone,
    year: "numeric",
    month: "short", // "long", "short", or "numeric"
    day: "2-digit"
  });
  const minDate = new Date();

  return (
    <>
      <ToastContainer />
      <div className="modal contact_schedule_wrap" onClick={closeModal}>
        <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="schedule_wrap">
              <div className="calander_wrap">
                <h2>Month</h2>
                <Calendar
                  onChange={setSelectedDate}
                  value={selectedDate}
                  minDate={minDate}
                  tileDisabled={({ date, view }) => {
                    if (view !== "month") return false; // Only disable tiles in month view
                
                    const formattedDate = date.toLocaleDateString("en-US", {
                      // timeZone: userTimeZone,
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    });
                
                    // Check if the day has any available slots
                    const slotsForDate = availableSlots[formattedDate];
                    const bookedSlotsForDate = bookedSlots[formattedDate] || [];
                
                    // Disable the day if there are no slots or all slots are booked
                    if (!slotsForDate || slotsForDate.length === 0) {
                      return true; // No available slots for this date
                    }
                
                    if (
                      slotsForDate.every((slot) =>
                        bookedSlotsForDate.includes(slot.time)
                      )
                    ) {
                      return true; // All slots are booked for this date
                    }
                
                    return false; // Enable the date
                  }}
                  
                />
                <button className="request_modal" onClick={closeModal}>
                  Cancel
                </button>
              </div>

              <div className="available_time">
                <h2>AVAILABLE TIMES</h2>
                <div className="slots_wrap">
                  <ul className="list-none flex flex-wrap">
                    {availableSlots[formattedSelectedDate] &&
                    availableSlots[formattedSelectedDate].filter(
                      (slot) =>
                        !bookedSlots[formattedSelectedDate]?.includes(slot.time)
                    ).length > 0 ? (
                      availableSlots[formattedSelectedDate]
                        .filter(
                          (slot) =>
                            !bookedSlots[formattedSelectedDate]?.includes(slot.time)
                        )
                        .sort((a, b) => {
                          // Parsing the time for accurate comparison
                          const parseTime = (time) => {
                            const [hour, minutePart] = time.split(":");
                            const [minute, period] = minutePart.split(" ");
                            let adjustedHour = parseInt(hour, 10);
                            if (period === "P.M." && adjustedHour !== 12) {
                              adjustedHour += 12;
                            }
                            if (period === "A.M." && adjustedHour === 12) {
                              adjustedHour = 0;
                            }
                            return adjustedHour * 60 + parseInt(minute, 10);
                          };
                          return parseTime(a.time) - parseTime(b.time);
                        })

                        .map((slot) => (
                          <li key={slot._id}>
                            <label htmlFor={`slot-${slot._id}`} className="slots">
                              <input
                                id={`slot-${slot._id}`}
                                type="radio"
                                name="slot"
                                value={slot.time}
                                checked={selectedSlot === slot.time}
                                onChange={() => setSelectedSlot(slot.time)}
                              />
                              <span>{slot.time}</span>
                            </label>
                          </li>
                        ))
                    ) : (
                      <li className="w-100">No available slots for this date.</li>
                    )}
                  </ul>
                </div>
                <div className="schedule_form">
                  <ul className="list-none">
                    <li>
                      <input
                        required
                        className="user_input"
                        type="phone"
                        placeholder="Phone *"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      {phoneError && (
                        <span className="error-message">❌ {phoneError}</span>
                      )}
                    </li>
                    <li>
                      <input
                        required
                        className="user_input"
                        type="email"
                        placeholder="Email *"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {emailError && (
                        <span className="error-message">❌ {emailError}</span>
                      )}
                    </li>
                    <li>
                      <button
                        className="request_modal"
                        onClick={handleScheduleAppointment}
                        disabled={loading}
                      >
                        {loading ? "Scheduling..." : "Schedule"}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleModal;
