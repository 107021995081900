import { NavLink } from "react-router-dom";
import "./header.scss"
import { useState } from "react";
import OutsideClickHandler from 'react-outside-click-handler';

export default function Header() {
    const [showMenu, setShowMenu] = useState(false);
    const openMenu = () => {setShowMenu(!showMenu);}
    return(
        <>
            <div className="header_wrap desktop_wrap">
                <div className="container">
                    <OutsideClickHandler
                        onOutsideClick={() => {
                            setShowMenu(false);
                        }}
                    >
                        <button onClick={openMenu} className={`menu_btn ${showMenu ? "active" : ''}`}>
                            <span></span>
                        </button>
                        <div className={`navigation_wrap ${showMenu ? "active" : ""}`}>
                            <ul className="list-none flex">
                                <li><NavLink onClick={()=>setShowMenu(false)} exact activeClassName="active" to="/">HOME</NavLink></li>
                                <li><NavLink onClick={()=>setShowMenu(false)} activeClassName="active" to="/free-shop">FREE-SHOP</NavLink></li>
                                <li><NavLink onClick={()=>setShowMenu(false)} activeClassName="active" to="/contact">CONTACT</NavLink></li>
                                <li><NavLink onClick={()=>setShowMenu(false)} activeClassName="active" to="/gift">GIFT</NavLink></li>
                                <li><NavLink onClick={()=>setShowMenu(false)} activeClassName="active" to="/the-book">THE BOOK</NavLink></li>
                            </ul>
                        </div>
                    </OutsideClickHandler>
                </div>
            </div>
        </>
    )
}