import "./dashboard.scss";
import editWhite from "../../../assets/images/edit_white.svg";
import { useState, useEffect } from "react";
import greenCheck from "../../../assets/images/greencheck.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ordersList, getInventory,getAllOrderRequests, updateInventory, appointmentsList } from "../../../utils/api";

export default function Dashboard() {
    const [showInput, setShowInput] = useState(false);
    const [appointCount, setAppointCount] = useState('0');


    // State to store order statistics
    const [orderStats, setOrderStats] = useState({
        fulfilledOrders: 0,
        pendingOrders: 0,
        requestedOrders: 0,
        pendingAppointments: 0,
    });

    // State to store and update inventory data
    const [inventory, setInventory] = useState({
        physicalDelivery: false,
        quantity: 0,
    });

    // State for tracking changes in inventory
    const [updatedInventory, setUpdatedInventory] = useState({
        physicalDelivery: false,
        quantity: 0,
    });

    // Fetch order statistics from API when component mounts
    useEffect(() => {
        const fetchOrderStats = async () => {
            try {
                const response = await ordersList();
                const responseRequests = await getAllOrderRequests();
                const requestedOrders = responseRequests.data.data.length;



                
                const orders = response.data;

                const pendingOrders = orders.filter(order => order.status === "Pending").length;
                const fulfilledOrders = orders.filter(order => order.delivery === "Fulfilled").length;

                setOrderStats({
                    fulfilledOrders,
                    pendingOrders,
                    requestedOrders,
                    pendingAppointments: 0,

                });
            } catch (error) {
                console.error("Error fetching order stats:", error);
            }
        };

        fetchOrderStats();
    }, []);

    // Fetch inventory data from API when component mounts
    useEffect(() => {
        const fetchInventory = async () => {
            try {
                const response = await getInventory();
                const appointmentResponse = await appointmentsList();
                setAppointCount(appointmentResponse?.data?.filter(x=> x.status === 'Pending').length)


                const inventoryData = response.data;

                setInventory({
                    physicalDelivery: inventoryData.physicalDelivery,
                    quantity: inventoryData.quantity,
                });

                // Initialize updatedInventory with current inventory data
                setUpdatedInventory({
                    physicalDelivery: inventoryData.physicalDelivery,
                    quantity: inventoryData.quantity,
                });
            } catch (error) {
                console.error("Error fetching inventory data:", error);
            }
        };

        fetchInventory();
    }, []);

    // Function to handle icon click for quantity update
    const handleIconClick = async () => {
        if (showInput) {
            try {
                const payload = {
                    physicalDelivery: updatedInventory.physicalDelivery,
                    quantity: updatedInventory.quantity,
                    restockDate: "2024-12-01T00:00:00Z",
                };

                await updateInventory(payload);

                // Update inventory with the new values
                setInventory(updatedInventory);
                toast.success("Inventory updated successfully!");
                
            } catch (error) {
                console.error("Error updating inventory:", error);
                toast.error("Failed to update inventory.");
            }
        }
        setShowInput(!showInput);
    };

    // Function to handle physical delivery toggle
    const handlePhysicalToggle = async (e) => {
        const newPhysicalDelivery = e.target.checked;

        setUpdatedInventory((prev) => ({
            ...prev,
            physicalDelivery: newPhysicalDelivery,
        }));

        try {
            await updateInventory({
                ...updatedInventory,
                physicalDelivery: newPhysicalDelivery,
                restockDate: "2024-12-01T00:00:00Z",
            });
            setInventory((prev) => ({
                ...prev,
                physicalDelivery: newPhysicalDelivery,
            }));
            toast.success("Physical delivery status updated!");
        } catch (error) {
            console.error("Error updating physical delivery status:", error);
            toast.error("Failed to update physical delivery status.");
        }
    };

    return (
        <>
            <ToastContainer />

            <div className="dashboard_wrapper">
                <div className="container">
                    <div className="dashboard_boxes">
                        <ul className="list-none grid-container-4">
                            <li>
                                <section>
                                    <h2>{orderStats.fulfilledOrders}</h2>
                                    <span>Fulfilled Orders</span>
                                </section>
                            </li>
                            <li>
                                <section>
                                    <h2>{orderStats.pendingOrders}</h2>
                                    <span>Orders Awaiting Approval</span>
                                </section>
                            </li>
                            <li>
                                <section>
                                    <h2>{orderStats.requestedOrders}</h2>
                                    <span>Requested Orders</span>
                                </section>
                            </li>
                            <li>
                                <section>
                                    <h2>{appointCount}</h2>
                                    <span>Pending Appointments</span>
                                </section>
                            </li>
                        </ul>
                    </div>
                    <div className="second_section">
                        <ul className="list-none">
                            <li>
                                <section>
                                    <h2>Update Book Inventory</h2>
                                    <p>
                                        {
                                            showInput ? (
                                                <input
                                                    type="number"
                                                    min="0"
                                                    placeholder="Enter Available Book Stock"
                                                    value={updatedInventory.quantity}
                                                    onChange={(e) => {
                                                        let newValue = e.target.value;

                                                        // Allow backspace (empty input) or a number (positive or zero)
                                                        if (newValue === '' || /^[0-9]*$/.test(newValue)) {
                                                            // Only update the state if the value is a valid non-negative number
                                                            setUpdatedInventory({
                                                                ...updatedInventory,
                                                                quantity: newValue === '' ? '' : Number(newValue),
                                                            });
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                inventory.quantity
                                            )
                                        }
                                        <img
                                            onClick={handleIconClick}
                                            src={`${showInput ? greenCheck : editWhite}`}
                                            alt="editWhite"
                                        />
                                    </p>
                                </section>
                            </li>
                            <li>
                                <section>
                                    <h2>Physical Copy Delivery</h2>
                                    <label className="switch_wrap" htmlFor="switch">
                                        <input
                                            type="checkbox"
                                            id="switch"
                                            checked={updatedInventory.physicalDelivery}
                                            onChange={handlePhysicalToggle}
                                        />
                                        <span></span>
                                    </label>
                                </section>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
