import { Link } from "react-router-dom"
import "./footer.scss"
import footerIcon from "../../assets/images/footericon.svg"

export default function Footer() {
    return(
        <>
            <div className="footer_wrap">
                <div className="container">
                    <Link to="https://www.instagram.com/lej.ah/" target="_blank"><img src={footerIcon} alt="footer"/></Link>
                </div>
            </div>
        </>
    )
}