import { useEffect, useState, useRef } from "react";
import trash from "../../../assets/images/trash.svg";
import searchIcon from "../../../assets/images/search.svg";
import calendarIcon from "../../../assets/images/calendar.svg";
import slotCross from "../../../assets/images/slot-cross.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DeleteModal from "../../../components/modal/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getShipping, addShipping, deleteShipping } from "../../../utils/api";
import engleLeft from "../../../assets/images/angle-left.svg";
import engleRight from "../../../assets/images/angle-right.svg";

export default function Shipping() {
  const [selectedDates, setSelectedDates] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const datePickerRef = useRef(null);
  const [selectedFilterDate, setSelectedFilterDate] = useState("");

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


  const [shippingList, setShippingList] = useState([]); 
  const [shippingListFiltered, setShippingListFiltered] = useState([]); 

  const [isDelModalOpen, setIsDelModalOpen] = useState(false); 
  const [newShipping, setNewShipping] = useState({
    order: "",
    shippingProvider: "",
    trackingNumber: "",
    shippingDate: "",
    deliveryDate: "",
    status: "Pending", 
  });

  const [shippingToDelete, setShippingToDelete] = useState(null);

  useEffect(() => {
    const fetchShippingData = async () => {
      try {
        const data = await getShipping();
        console.log("Fetched Shipping Data:", data);
        setShippingList(data.data || []);
      } catch (error) {
        console.error("Error fetching shipping data", error);
      }
    };
  
    fetchShippingData();
  }, [selectedDates]);

  const handleDateChange = (date) => {
    if (date && !selectedDates.some(d => d.getTime() === new Date(date).getTime())) {
      setSelectedDates([...selectedDates, new Date(date)]);
    }
  };

  const removeDate = (dateToRemove) => {
    setSelectedDates(selectedDates.filter((date) => date.getTime() !== dateToRemove.getTime()));
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setIsDatePickerOpen(false);
      }
    };

    // Avoid closing date picker when clicking on arrows
    const preventCloseOnArrowClick = (event) => {
      if (event.target.closest('.react-datepicker__navigation')) {
        event.stopPropagation(); // Stop event propagation when clicking on arrows
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("click", preventCloseOnArrowClick); // Prevent closing on arrow clicks

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("click", preventCloseOnArrowClick);
    };
  }, []);

  const openDelModal = (shippingId, date) => {
    setShippingToDelete({ shippingId, date });
    setIsDelModalOpen(true);
  };

  const closeModal = () => setIsDelModalOpen(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedDates.length > 0) {
      const shippingData = {
        ...newShipping,
        shippingDate: selectedDates.map(date => date.toISOString()),
      };

      try {
        const createdShipping = await addShipping(shippingData);
        console.log("Created Shipping Response:", createdShipping);
        toast.success("Shipping date added successfully.");


        if (createdShipping && createdShipping.shippingDate) {
          setShippingList((prevShippingList) => [
            ...prevShippingList,
            createdShipping,
          ]);
        }

        setSelectedDates([]);
        setNewShipping({
          order: "",
          shippingProvider: "",
          trackingNumber: "",
          shippingDate: "",
          deliveryDate: "",
          status: "Pending",
        });

      } catch (error) {
        console.error("Error adding shipping", error);
      }
    } else {
        toast.error("Please select at least one shipping date.");
    }
  };

  const handleDeleteShipping = async () => {
    console.log("Deleted Shipping with ID:", "and Date:", shippingToDelete);
  
    const { shippingId, date } = shippingToDelete;
  
    if (shippingId && date) {
      try {
        const dateToSend = { dateId: date };
  
        await deleteShipping(shippingId, dateToSend);
  
        console.log("Deleted Shipping with ID:", shippingId, "and Date:", date);
        toast.success("Shipping date deleted successfully.");
  
        // Re-fetch the updated shipping list
        const updatedData = await getShipping();
        setShippingList(updatedData.data || []);
  
        closeModal();
      } catch (error) {
        if (error.status === 400) {
          toast.warn(
            "There is shipping scheduled for this date and it cannot be deleted."
          );
          setTimeout(() => {
            closeModal();
          }, 2000);
  
          return;
        }
        console.error("Error deleting shipping", error);
      }
    }
  };
  
  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  const handleFilterDateChange = (event) => {

    // if(event.target.value !== ''){
      setSelectedFilterDate(event.target.value);
      // alert(event.target.value)
    // }
  };

  useEffect(() => {
    if (selectedFilterDate) {
      const filtered = shippingList.filter((item) =>
        item.shippingDate.some(
          (date) => new Date(date.date).toDateString() === new Date(selectedFilterDate).toDateString()
        )
      );
      setShippingListFiltered(filtered);
    } else {
      setShippingListFiltered(shippingList); // Show all items if no date is selected
    }
  }, [selectedFilterDate, shippingList]);

  console.log(shippingList,"uuio",new Date(shippingList[0]?.shippingDate[0]?.date))

  return (
    <>
      <ToastContainer />

      {isDelModalOpen && (
        <DeleteModal
          closeModal={closeModal}
          onConfirm={handleDeleteShipping}
        />
      )}
      <div className="table_layout_wrapper mini_table">
        <div className="container">
          <div className="table_title">
            <h2>Add Shipping Dates</h2>
          </div>
          <div className="shipping_frequency">
            <p>Select Shipping Date</p>
            <form onSubmit={handleSubmit}>
              <div className="input_field">
                <div className="slot_wrap">
                  {selectedDates.map((date, index) => (
                    <div key={index} className="slot">
                      {date.toLocaleDateString("en-US", {
                                            // timeZone: userTimeZone,
                                            year: "numeric",
                                            month: "short", // "long", "short", or "numeric"
                                            day: "2-digit"
                                          })}
                      <img
                        onClick={() => removeDate(date)}
                        src={slotCross}
                        alt="slot-cross"
                      />
                    </div>
                  ))}
                </div>
                <img src={calendarIcon} alt="calendarIcon" onClick={toggleDatePicker} />
                {isDatePickerOpen && (
                  <div
                    ref={datePickerRef}
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "63px",
                      zIndex: 1000,
                    }}
                  >
                    <DatePicker
                      selected={startDate}
                      onChange={handleDateChange}
                      startDate={startDate}
                      inline
                      placeholderText="Click to select dates"
                      highlightDates={selectedDates}
                      minDate={new Date()} 
                      selectsMultiple 
                      // excludeDates={[new Date("2024-12-12")]}
                      excludeDates={shippingListFiltered.flatMap(x =>
                        x.shippingDate.map(date => new Date(date.date))
                      )}
                    />
                  </div>
                )}
              </div>
              <button type="submit" className="simple_search_btn">
                Submit
              </button>
            </form>
          </div>

          {/* Shipping List */}
          <div className="table_title">
            <h2>Upcoming Shipping Dates</h2>
            <div className="table_search">
              <form>
                <ul className="list-none flex">
                  <li>
                    <div className="input_with_calendar">
                      <input
                        type="date"
                        autocomplete="off"
                        value={selectedFilterDate}
                        onChange={handleFilterDateChange}
                      />
                      <img src={calendarIcon} alt="calendar" />
                    </div>
                  </li>
                </ul>
              </form>
              <div className="pagination_wrap">
                <h6>{shippingListFiltered.length} Items</h6>
                <ul className="list-none flex">
                  <li>
                    <button>
                      <img src={engleLeft} alt="angle-left" />
                    </button>
                  </li>
                  <li>
                    <button className={`active`}>1</button>
                  </li>
                  <li>
                    <button>
                      <img src={engleRight} alt="angle-right" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="table_layout">
            <div className="table_layout_responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: "200px" }}>Reserved Date</th>
                    <th style={{ width: "auto" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(shippingListFiltered) && shippingListFiltered.length > 0 ? (
                    shippingListFiltered.map((shipping) => {
                      const shippingDates = Array.isArray(shipping.shippingDate)
                        ? shipping.shippingDate
                        : [shipping.shippingDate];

                      return shippingDates.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((date, idx) => (
                        <tr key={`${shipping._id}-${idx}`}> {/* Use a combination of shipping._id and index to ensure unique keys */}
                          <td style={{whiteSpace: "nowrap"}}>
                            {/* Format and display each date */}
                            {isValidDate(new Date(date?.date)) ? new Date(date?.date).toLocaleDateString("en-US", {
                                            // timeZone: userTimeZone,
                                            year: "numeric",
                                            month: "short", // "long", "short", or "numeric"
                                            day: "2-digit"
                                          }) : "Invalid Date"}
                          </td>
                            <td>
                            <div className="actions">
                              <img
                                onClick={() => openDelModal(shipping._id, date?._id)} // Pass both the shippingId and the specific date to delete
                                    src={trash}
                                    alt="trash"
                                  />
                              </div>
                            </td>
                          </tr>
                      ));
                    })
                  ) : (
                    <tr>
                      <td colSpan="2">No shipping dates available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
