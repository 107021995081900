import { useState, useEffect, useRef } from "react";
import editWhite from "../../../assets/images/edit.svg";
import engleLeft from "../../../assets/images/angle-left.svg";
import engleRight from "../../../assets/images/angle-right.svg";
import greenCheck from "../../../assets/images/greencheck.svg";
import calendarIcon from "../../../assets/images/calendar.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { appointmentsList, updateAppointment } from "../../../utils/api"; // Assuming you have an API call for appointments

export default function Appointments() {
    const [showInputIndex, setShowInputIndex] = useState(null);
    const [searchDate, setSearchDate] = useState(null);
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSearchDatePickerOpen, setIsSearchDatePickerOpen] = useState(false);
    const [statusUpdateLoading, setStatusUpdateLoading] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const searchDatePickerRef = useRef(null);
    const [selectedStatusFilter, setSelectedStatusFilter] = useState("All");
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const toggleSearchDatePicker = () => {
        setIsSearchDatePickerOpen(!isSearchDatePickerOpen);
    };

    const handleSearchDateChange = (date) => {
        setSearchDate(date);
        setIsSearchDatePickerOpen(false);
    };

    const fetchAppointments = async () => {
        try {
            const response = await appointmentsList();
            setAppointments(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching appointments:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAppointments();
    }, [showInputIndex, selectedStatus]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchDatePickerRef.current && !searchDatePickerRef.current.contains(event.target)) {
                setIsSearchDatePickerOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleInput = (index) => {
        if (showInputIndex === index) {
            setShowInputIndex(null);
            setSelectedStatus(null);
        } else {
            setShowInputIndex(index);
            setSelectedStatus(appointments[index].status);
        }
    };

    const totalPages = Math.ceil(appointments.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;

    // Filter appointments based on selected searchDate
    const filteredAppointments = appointments.filter((appointment) => {
        const appointmentDate = new Date(appointment.date);
    
        // Check if appointment matches the selected date
        const matchesDate = searchDate
            ? appointmentDate.toLocaleDateString("en-US", { timeZone: userTimeZone }) ===
              searchDate.toLocaleDateString("en-US", { timeZone: userTimeZone })
            : true;
    
        // Check if appointment matches the selected status
        const matchesStatus =
            selectedStatusFilter === "All" || appointment.status === selectedStatusFilter;
    
        return matchesDate && matchesStatus;
    });
    

    const currentAppointments = filteredAppointments.slice(startIndex, startIndex + itemsPerPage);

    const handleStatusFilterChange = (e) => {
        setSelectedStatusFilter(e.target.value);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleStatusUpdate = async (appointmentId) => {
        try {
            if (!selectedStatus) return;

            setStatusUpdateLoading(appointmentId);
            const response = await updateAppointment(appointmentId, { status: selectedStatus });

            if (response.status === 200) {
                setAppointments((prevAppointments) =>
                    prevAppointments.map((appointment) =>
                        appointment._id === appointmentId
                            ? { ...appointment, status: selectedStatus }
                            : appointment
                    )
                );
                toast.success("Appointment status successfully updated.");
            } else {
                toast.error("Failed to update status");
            }
        } catch (error) {
            console.error("Error updating appointment status:", error);
            toast.error("Failed to update status");
        } finally {
            setStatusUpdateLoading(null);
            setShowInputIndex(null);
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="table_layout_wrapper">
                <div className="container">
                    <div className="table_title">
                        <h2>Scheduled Appointments</h2>
                        <div className="table_search">
                            <form>
                                <ul className="list-none flex">
                                    <li>
                                        <select className="" value={selectedStatusFilter} onChange={handleStatusFilterChange}>
                                            <option>All</option>
                                            <option>Pending</option>
                                            <option>Completed</option>
                                        </select>
                                    </li>
                                    <li>
                                        <div className="input_with_calendar">
                                            <input
                                                onClick={toggleSearchDatePicker}
                                                placeholder="Search by Date"
                                                value={searchDate ? searchDate.toLocaleDateString("en-US", {
                                                    // timeZone: userTimeZone,
                                                    year: "numeric",
                                                    month: "short",
                                                    day: "2-digit",
                                                }) : ""}
                                                readOnly
                                            />
                                            <img
                                                src={calendarIcon}
                                                alt="calendar"
                                                onClick={toggleSearchDatePicker}
                                            />
                                            {isSearchDatePickerOpen && (
                                                <div
                                                    ref={searchDatePickerRef}
                                                    style={{ position: "absolute", zIndex: 1000 }}
                                                >
                                                    <DatePicker
                                                        selected={searchDate}
                                                        onChange={handleSearchDateChange}
                                                        inline
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                </ul>
                            </form>
                            <div className="pagination_wrap">
                                <h6>{filteredAppointments.length} Items</h6>
                                <ul className="list-none flex">
                                    <li>
                                        <button
                                            disabled={currentPage === 1}
                                            onClick={() => handlePageChange(currentPage - 1)}
                                        >
                                            <img src={engleLeft} alt="angle-left" />
                                        </button>
                                    </li>
                                    {[...Array(totalPages)].map((_, index) => (
                                        <li key={index}>
                                            <button
                                                className={currentPage === index + 1 ? "active" : ""}
                                                onClick={() => handlePageChange(index + 1)}
                                            >
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                    <li>
                                        <button
                                            disabled={currentPage === totalPages}
                                            onClick={() => handlePageChange(currentPage + 1)}
                                        >
                                            <img src={engleRight} alt="angle-right" />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="table_layout">
                        <div className="table_layout_responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Email</th>
                                        <th>Appointment Status</th>
                                        <th>Phone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td colSpan="5" style={{ textAlign: "center" }}>Loading...</td>
                                        </tr>
                                    ) : currentAppointments.length === 0 ? (
                                        <tr>
                                            <td colSpan="5" style={{ textAlign: "center" }}>No Record Found</td>
                                        </tr>
                                    ) : (
                                        currentAppointments
                                            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                            .map(({ _id, email, date, time, phone, status }, index) => (
                                                <tr key={_id}>
                                                    <td>{new Date(date).toLocaleDateString("en-US", {
                                                        // timeZone: userTimeZone,
                                                        year: "numeric",
                                                        month: "short",
                                                        day: "2-digit",
                                                    })}</td>
                                                    <td>{time}</td>
                                                    <td>
                                                        <Link className="innerLink" to={`mailto:${email}`}>
                                                            {email}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <div className="actions">
                                                            {showInputIndex === index ? (
                                                                <select
                                                                    value={selectedStatus || status || "Pending"}
                                                                    onChange={(e) => setSelectedStatus(e.target.value)}
                                                                >
                                                                    <option value="Pending">Pending</option>
                                                                    <option value="Completed">Completed</option>
                                                                </select>
                                                            ) : (
                                                                status || "Pending"
                                                            )}
                                                            <img
                                                                onClick={() => {
                                                                    toggleInput(index);
                                                                    if (showInputIndex === index) {
                                                                        handleStatusUpdate(_id);
                                                                    }
                                                                }}
                                                                src={
                                                                    showInputIndex === index
                                                                        ? greenCheck
                                                                        : editWhite
                                                                }
                                                                alt={
                                                                    showInputIndex === index
                                                                        ? "save"
                                                                        : "edit"
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>{phone}</td>
                                                </tr>
                                            ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
