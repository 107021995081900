import "./login.scss";
import loginImage from "../../assets/images/login.svg";
import eye from "../../assets/images/eye.svg";
import eyeOff from "../../assets/images/eye-off.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { adminLogin } from "../../utils/api";



export default function Login() {
    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
      });
    const navigate = useNavigate();

    // Hardcoded admin credentials
    // const adminEmail = "admin@example.com";
    // const adminPassword = "admin123";

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    // const handleLogin = (e) => {
    //     e.preventDefault();

    //     // Check if entered credentials match the hardcoded admin credentials
    //     if (email === adminEmail && password === adminPassword) {
    //         // Redirect to /admin if credentials are correct
    //         localStorage.setItem("adminLoggedIn", true);// Example using localStorage
    //         navigate("/admin");
    //     } else {
    //         // Show error message if credentials don't match
    //         toast.error("These credentials do not match our records.");
    //     }
    // };

    const handleLogin = async (event) => {
        event.preventDefault();
    
        try {
          const response = await adminLogin(credentials);
          if (response.status === 200) {
            typeof window !== 'undefined' && localStorage.setItem('admintoken', response.data.token);
            toast.success("Login successful! Redirecting...", {
              position: "top-right",
              autoClose: 2000,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            
            setTimeout(() => {
              if (typeof window !== 'undefined') {
                navigate("/admin");
              }
            }, 1000);
          }
        } catch (error) {
          toast.error("These credentials do not match our records.", {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      };

      const handleChange = (event) => {
        setCredentials({ ...credentials, [event.target.name]: event.target.value });
      };

    return (
        <>
        <ToastContainer/>
            <div className="login_wrap">
                <section>
                    <img src={loginImage} alt="loginImage" />
                    <h1>Admin</h1>
                    <form onSubmit={handleLogin}>
                        <ul className="list-none">
                            <li>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    value={credentials.email}
                                    onChange={handleChange}
                                    id="email"
                                    />
                            </li>
                            <li>
                                <div className="password">
                                <input
                                    type={passwordVisible ? "text" : "password"}
                                    placeholder="Password"
                                    name="password"
                                    value={credentials.password}
                                    onChange={handleChange}
                                    id="password"
                                />
                                    <img
                                        src={passwordVisible ? eyeOff : eye}
                                        alt="toggle password visibility"
                                        onClick={togglePasswordVisibility}
                                    />
                                </div>
                            </li>
                            {errorMessage && (
                                <li
                                    className="error-message"
                                    style={{
                                        color: 'white',                  // Set text color to white
                                        fontSize: '14px',                 // Optional: Set font size
                                        marginTop: '10px',                // Optional: Add space above the message
                                        textAlign: 'center',              // Optional: Center the text
                                        backgroundColor: '#f44336',      // Optional: Set background color (red)
                                        padding: '10px',                  // Optional: Add padding
                                        borderRadius: '5px'               // Optional: Round corners of the box
                                    }}
                                >
                                    {errorMessage}
                                </li>
                            )}

                            <li>
                                <button type="submit" className="simple_btn">Login</button>
                            </li>
                        </ul>
                    </form>
                </section>
            </div>
        </>
    );
}
