import { Link, Outlet, useNavigate } from "react-router-dom";
import AdminSidebar from "../components/sidebar/Sidebar";

export const AdminLayout = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Remove adminLoggedIn from localStorage
        localStorage.removeItem("admintoken");

        // Redirect to the login page
        navigate("/login");
    };

    return (
        <>
        <div className="admin_wrapper">
            <AdminSidebar />
            <div className="admin_content_wrapper">
                <div className="logout">
                    <Link to="/login" onClick={handleLogout}>Logout</Link>
                </div>
                <div className="inner_content_wrap">
                    <Outlet />
                </div>
            </div>
        </div>
        </>
    );
};
