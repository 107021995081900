import React from "react";
import "./modal.scss";
import closeIcon from "../../assets/images/close.svg"

const DeleteModal = ({ closeModal, show, children, onConfirm   }) => {

  // {show, onClose, children, onClickYes }) 
  
  return (
    <>
    <div className="modal del_modal" onClick={closeModal}>
        <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
                <button type="button" className="close" onClick={closeModal}>
                  <img src={closeIcon} alt="close"/>
                </button>
                <div className="first_modal">
                    <h2>Delete</h2>
                    <p>Are you sure you want to delete?</p>
                    <div className="button_wrap">
                      <button className="request_modal"
                        onClick={()=>{
                          onConfirm()
                          closeModal()
                          }
                        }
                      >
                        Yes
                      </button>
                      <button className="request_modal" onClick={closeModal}>
                        No
                      </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default DeleteModal;