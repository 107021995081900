import { useEffect, useState } from "react";
import PhysicalModal from "../../components/modal/PhysicalModal";
import "./opt.scss";
import DigitalModal from "../../components/modal/DigitalModal";
import PhysicalRequestModal from "../../components/modal/PhysicalRequestModal";
import OrderPlacedModal from "../../components/modal/OrderPlacedModal";
import RequestFormModal from "../../components/modal/RequestFormModal";
import PreviouslyRegisteredEmailModal from "../../components/modal/PreviouslyRegisteredEmailModal";
import { getInventory } from "../../utils/api"; // Assuming getInventory function is imported here.

export default function FreeShop() {
    const [isPhysicalModalOpen, setIsPhysicalModalOpen] = useState();
    const [isPhysicalRequestModalOpen, setIsPhysicalRequestModalOpen] = useState(false);
    const [isDigitalModalOpen, setIsDigitalModalOpen] = useState(false);
    const [isRequestFormModalOpen, setIsRequestFormModalOpen] = useState(false);
    const [isPreviouslyModalOpen, setIsPreviouslyModalOpen] = useState(false);
    const [isOrderPlacedOpen, setIsOrderPlacedOpen] = useState(false);
    const [orderPlacedData, setOrderPlacedData] = useState();

    const [physicaƒlDelivery, setPhysicalDelivery] = useState(false);  // New state for physical delivery check

    const openPhysicalRequestModal = () => {
        setIsPhysicalModalOpen(false);
        setIsPhysicalRequestModalOpen(true);
    };

    const openRequestFormModal = () => {
        setIsPhysicalRequestModalOpen(false);
        setIsRequestFormModalOpen(true);
    };

    const openOrderPlacedModal = (data) => {
        setIsRequestFormModalOpen(false);
        setIsOrderPlacedOpen(true);
        setOrderPlacedData(data)
        console.log(data,"opo")
    };

    const openPhysicalModal = () => setIsPhysicalModalOpen(true);
    const openDigitalModal = () => setIsDigitalModalOpen(true);
    const openPreviouslyModal = () => setIsPreviouslyModalOpen(true);

    const closeModal = () => {
        setIsPhysicalModalOpen(false);
        setIsDigitalModalOpen(false);
    };

    const closePrevModal = () => { setIsPreviouslyModalOpen(false); }
    const closePhyModal = () => { setIsPhysicalRequestModalOpen(false); }
    const closeReqModal = () => { setIsRequestFormModalOpen(false); }
    const closeOrdModal = () => { setIsOrderPlacedOpen(false); }

    const [isVisible, setIsVisible] = useState(false);

    // Fetch inventory and check physicalDelivery value
    // useEffect(() => {
    //     const fetchInventory = async () => {
    //         try {
    //             const response = await getInventory(); // Assuming this API gives the response structure
    //             if (response.data && response.data.physicalDelivery !== undefined) {
    //                 setPhysicalDelivery(response.data.physicalDelivery); // Update state with physicalDelivery value
    //                 if (response.data.physicalDelivery) {
    //                     setIsPhysicalRequestModalOpen(response.data.physicalDelivery); // Open Physical Modal if physicalDelivery is true
    //                 }
    //             }
    //         } catch (error) {
    //             console.error("Error fetching inventory:", error);
    //         }
    //     };

    //     fetchInventory(); // Fetch inventory on component mount
    // }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 100);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <div className={`main_bg_wrapper ${isVisible ? 'visible' : ''}`}>
                <div className="inner_wrapper">
                    <button className="main_btns" onClick={openPhysicalModal}>Physical edition</button>
                    <button className="main_btns" onClick={openDigitalModal}>Digital Manuscript</button>
                </div>
            </div>

            {/* Modal Components */}
            {isPhysicalModalOpen && (
                <PhysicalModal
                    closeModal={closeModal}
                    openPhysicalRequestModal={openPhysicalRequestModal}
                    openRequestFormModal={openRequestFormModal}
                    isOpen={isPhysicalModalOpen}
                />
            )}
            {isPhysicalRequestModalOpen && (
                <PhysicalRequestModal
                    closeModal={closePhyModal}
                    openRequestFormModal={openRequestFormModal}
                    isOpen={isPhysicalRequestModalOpen}
                />
            )}
            {isRequestFormModalOpen && (
                <RequestFormModal
                    closeModal={closeReqModal}
                    openPreviouslyModal={openPreviouslyModal}
                    openOrderPlacedModal={openOrderPlacedModal}
                    isOpen={isRequestFormModalOpen}
                />
            )}

            {isOrderPlacedOpen && (
                <OrderPlacedModal
                    closeModal={closeOrdModal}
                    isOpen={isOrderPlacedOpen}
                    orderPlacedData={orderPlacedData}
                />
            )}
            
            {isPreviouslyModalOpen && (
                <PreviouslyRegisteredEmailModal
                    closeModal={closePrevModal}
                    isOpen={isPreviouslyModalOpen}
                />
            )}
            {isDigitalModalOpen && (
                <DigitalModal
                    closeModal={closeModal}
                    isOpen={isDigitalModalOpen}
                />
            )}
        </>
    );
}
