import React, { useEffect, useRef, useState } from "react";
import "./banner.scss"
import bannerPoster from "../../../assets/images/banner_poster.jpg"
import greencheck from "../../../assets/images/greencheck.svg"
import outstock from "../../../assets/images/outstock.svg"
import soundOff from "../../../assets/images/sound-off.svg"
import soundOn from "../../../assets/images/sound-on.svg"
import { getInventory, getShipping } from "../../../utils/api"; // Assuming getInventory function is imported here.
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CancelModal from "../../../components/modal/CancelModal";
import TokenExpiryModal from "../../../components/modal/TokenExpiryModal";
import SuccessModal from "../../../components/modal/SuccessModal";
import AdminRejected from "../../../components/modal/AdminRejected";
import AdminFulfilled from "../../../components/modal/AdminFulfilled";


export default function Banner() {
    const videoRef = useRef(null);
    const [isMuted, setIsMuted] = useState(true); // Default is muted
    const [isVisible, setIsVisible] = useState(false);
    const [isPhysicalDeliveryAvailable, setIsPhysicalDeliveryAvailable] = useState(false);
    const [isCancelModal, setIsCancelModal] = useState(false);
    const [isSuccessModal, setIsSucessModal] = useState(false);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [isAdminRejected, setIsAdminRejected] = useState(false);
    const [isAdminFulfilled, setIsAdminFulfilled] = useState(false);

    const closeReqModal = () => { 
        setIsCancelModal(false);
        setIsSucessModal(false);
        setIsTokenExpired(false);
        setIsAdminRejected(false);
        setIsAdminFulfilled(false);

                
     }


    const toggleMute = () => {
        setIsMuted(prev => {
            const newMutedState = !prev;
            if (videoRef.current) {
                videoRef.current.muted = newMutedState; // Set the video muted state
            }
            return newMutedState;
        });
    };

    // status=succes
    

    const checkShippingDates = async () => {
        try {
            // Fetch shipping data


        } catch (error) {
            console.error("Error checking shipping dates:", error);
            return false; // In case of an error, return false
        }
    };

    useEffect(() => {
        if(window.location.href.includes("already_cancelled")){
            
            // toast.success("You have already cancelled this order.");
            setIsSucessModal(true);
        }
        if(window.location.href.includes("status=succes")){
            // toast.success("Your order has been cancelled.");
            setIsCancelModal(true);
        } 
        else if(window.location.href.includes("timeexpired"))
            {
                setIsTokenExpired(true)
                // toast.error("Order cancellation is no longer available after 24 hours. Please contact us if you need further assistance.");
                
            }
        else if (window.location.href.includes("already_fulfilled"))
        {
            setIsAdminFulfilled(true);

        }
        else if(window.location.href.includes("admin_rejected"))
             {
                setIsAdminRejected(true)
                // toast.error("Order cancellation is no longer available after 24 hours. Please contact us if you need further assistance.");
            }
            
        else if(window.location.href.includes("ordernotfound"))
        {
            toast.error("We couldn't find your order. Please reach out to the author directly for assistance.");
            
        }

        const fetchInventory = async () => {
            try {
                const response = await getInventory(); // Assuming this API gives the response structure

                const responseShipping = await getShipping();


                // Get the current date (at midnight)
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0); // Reset time portion to ensure comparison is only by date

                let shippingFutureDate;

                let highestDate = null;

                // Loop through all shipping records to find the highest shipping date
                for (let shipping of responseShipping?.data) {
                    if (shipping.shippingDate && shipping.shippingDate.length > 0) {
                        // Get the highest date from the current shipping record
                        const recordHighestDate = new Date(Math.max(
                            ...shipping.shippingDate.map(dateObj => new Date(dateObj.date).getTime())
                        ));
                
                        // If it's the first record or higher than the previous highest date, update highestDate
                        if (!highestDate || recordHighestDate > highestDate) {
                            highestDate = recordHighestDate;
                        }
                    }
                }
                
                // Now compare the highest shipping date with the current date
                 shippingFutureDate = highestDate && highestDate > currentDate;
                
                // Log or use the result
                console.log("Highest Shipping Date:", highestDate);
                console.log("Is there a future shipping date?", shippingFutureDate);
                

                if (response.data && response.data.physicalDelivery !== undefined ) {
                    // alert(shippingFutureDate)

                    // setPhysicalDelivery(response.data.physicalDelivery); // Update state with physicalDelivery value
                    if (response.data.physicalDelivery && response?.data?.quantity > 0 && shippingFutureDate) {
                        //   openRequestFormModal();
                        setIsPhysicalDeliveryAvailable(true)

                        // setIsPhysicalRequestModalOpen(response.data.physicalDelivery); // Open Physical Modal if physicalDelivery is true
                    } else {
                        setIsPhysicalDeliveryAvailable(false)
                    }
                }
            } catch (error) {
                console.error("Error fetching inventory:", error);
            }
        };

        fetchInventory()
        setIsVisible(true);

        // return () => clearTimeout(timer); // Cleanup timeout
    }, []);

    return (
        <>
        

        {isAdminRejected  &&
        <AdminRejected  closeModal={closeReqModal}/>
        }
        {isAdminFulfilled  &&
        <AdminFulfilled  closeModal={closeReqModal}/>
        }
        {isTokenExpired  &&
        <TokenExpiryModal  closeModal={closeReqModal}/>
        }
        {isCancelModal  &&
        <CancelModal  closeModal={closeReqModal}/>
        }
        {isSuccessModal  &&

        <SuccessModal  closeModal={closeReqModal}/>
        }

            <ToastContainer/>

            <div className={`banner_wrapper ${isVisible ? 'visible' : ''}`}>
                <video ref={videoRef} autoPlay loop muted playsInline preload="auto" poster={bannerPoster}>
                    <source src="https://frontend.development-env.com/book_videos/banner_video.mp4" type="video/mp4" />
                    <source src="https://frontend.development-env.com/book_videos/banner_video.ogg" type="video/ogg" />
                </video>
                <section>
                    {isPhysicalDeliveryAvailable ?
                        <h1><img src={greencheck} alt="greencheck" /> Physical edition currently in stock</h1>
                        :
                        <h1><img src={outstock} alt="outstock" style={{height: "50px"}} /> Physical edition currently out of stock.</h1>
                    }
                    <button onClick={toggleMute}>
                        {isMuted ?
                            <img src={soundOff} alt="Mute" /> :
                            <img className="unmute" src={soundOn} alt="unMute" />
                        }
                    </button>
                </section>
            </div>
        </>
    )
}