import React, { useRef, useState } from "react";
import "./modal.scss";
import { Link, useNavigate } from "react-router-dom";
import anim from "../../assets/images/anim.svg";
import { createOrder } from "../../utils/api"; // Import the createOrder function
import { useModal } from "../../contexts/ModalContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RequestFormModal = ({ closeModal }) => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    email: ''
  });

  const [errors, setErrors] = useState({
    name: false,
    address: false,
    email: false
  });

  const [activeField, setActiveField] = useState(null);

  const typingSoundRef = useRef(new Audio('https://frontend.development-env.com/book_videos/type-writing.mp3'));
  const switchSoundRef = useRef(new Audio('https://frontend.development-env.com/book_videos/typewriter-carriage.mp3'));

  const inputRefs = useRef([]);

  const navigate = useNavigate();

  const { openModal } = useModal();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    playTypingSound();
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false
      }));
  };

  const playTypingSound = () => {
    typingSoundRef.current.currentTime = 0;
    typingSoundRef.current.play().catch((error) => {
      console.error("Error playing sound:", error);
    });
  };

  const handleFieldFocus = (name) => {
    setActiveField(name);
    switchSoundRef.current.currentTime = 0;
    switchSoundRef.current.play().catch((error) => {
      console.error("Error playing sound:", error);
    });
  };

  const handleFieldBlur = () => {
    setActiveField(null);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === ' ') {
      playTypingSound();
    } else if (e.key === 'Enter') {
      playTypingSound();
      if (inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
        handleFieldFocus(inputRefs.current[index + 1].name);
      }
    }
  };

  const validateForm = () => {
    const newErrors = {
      name: !formData.name.trim(),
      address: !formData.address.trim(),
      email: !formData.email.trim(),
    };

    setErrors(newErrors);
    return !newErrors.name && !newErrors.address && !newErrors.email;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;    

    if(!emailRegex.test(formData.email)) {
      toast.error("Invalid email.")

      return;
    }

      try {
        const data = {
          name: formData.name,
          address: formData.address,
          email: formData.email
        };

        const response = await createOrder(data);
        if (response.status === 201) {
          openModal('OrderPlaced', response.data);
          navigate("/");
          // openOrderPlacedModal(response.data);
          closeModal();
        } else {
          console.error("Error creating order:", response);
          // response.status === 400 && openPreviouslyModal();
          openModal('PreviouslyRegistered');
          // navigate("/");
        }
      } catch (error) {
        console.error("Error creating order:", error);
        // openPreviouslyModal();
        openModal('PreviouslyRegistered');
        // navigate("/");
      }
    }
  };

  return (
    <>
      <ToastContainer />
    
    <div className="modal request_form_wrapper" onClick={closeModal}>
      <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <div className="request_form_wrap">
            <form>
              <ul className="list-none">
                <li>
                  <input
                    ref={(el) => inputRefs.current[0] = el}
                    type="text"
                    name="name"
                    required
                    placeholder="Name *"
                    className={`user_input ${errors.name ? 'error' : ''}`}
                    value={formData.name}
                    onChange={handleInputChange}
                    onFocus={() => handleFieldFocus('name')}
                    onBlur={handleFieldBlur}
                    onKeyDown={(e) => handleKeyDown(e, 0)}
                  />
                  {errors.name && <span className="error-message">❌ Required field</span>}
                </li>
                <li>
                  <input
                    ref={(el) => inputRefs.current[1] = el}
                    type="text"
                    name="address"
                    required
                    placeholder="ADDRESS *"
                    className={`user_input ${errors.address ? 'error' : ''}`}
                    value={formData.address}
                    onChange={handleInputChange}
                    onFocus={() => handleFieldFocus('address')}
                    onBlur={handleFieldBlur}
                    onKeyDown={(e) => handleKeyDown(e, 1)}
                  />
                  {errors.address && <span className="error-message">❌ Required field</span>}
                </li>
                <li>
                  <input
                    ref={(el) => inputRefs.current[2] = el}
                    type="email"
                    name="email"
                   
                    required
                    placeholder="Email *"
                    className={`user_input ${errors.email ? 'error' : ''}`}
                    value={formData.email}
                    onChange={handleInputChange}
                    onFocus={() => handleFieldFocus('email')}
                    onBlur={handleFieldBlur}
                    onKeyDown={(e) => handleKeyDown(e, 2)}
                  />
                  {errors.email && <span className="error-message">❌ Required field</span>}
                </li>
              </ul>
            </form>
            <div className="data_from_field">
              <ul className="list-none">
                <li>
                  <p>
                    {formData.name || ''}
                    {activeField === 'name' && <span className="typing-effect"><img src={anim} alt="anim" /></span>}
                  </p>
                </li>
                <li>
                  <p>
                    {formData.address || ''}
                    {activeField === 'address' && <span className="typing-effect"><img src={anim} alt="anim" /></span>}
                  </p>
                </li>
                <li>
                  <p>
                    {formData.email || ''}
                    {activeField === 'email' && <span className="typing-effect"><img src={anim} alt="anim" /></span>}
                  </p>
                </li>
              </ul>
              <div className="text-center">
                <button className="request_modal" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
            <div className="request_mini">
              <p>Please pay the $8 for shipping cost once you have received the product. In the <Link to="#">"Gift"</Link> section, you will find where to send the payment along with different options.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>

  );
};

export default RequestFormModal;
