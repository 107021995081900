import engleLeft from "../../../assets/images/angle-left.svg";
import engleRight from "../../../assets/images/angle-right.svg";
import trash from "../../../assets/images/trash.svg";
import slotCross from "../../../assets/images/slot-cross.svg";
import { useState,useMemo, useRef, useEffect } from "react";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from "../../../assets/images/calendar.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAvailability, deleteAvailabilityTimeSlot, updateAvailability, appointmentsList, deleteAvailability } from "../../../utils/api"; // Add API calls
import DeleteModal from "../../../components/modal/DeleteModal";

const CustomMultiValueRemove = (props) => {
    return (
        <img {...props.innerProps} src={slotCross} alt="remove" />
    );
};

const customStyles = {
    multiValue: (base) => ({
        ...base,
        position: "relative",
        minWidth: "90px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 16px 0 12px",
        backgroundColor: "#19191D",
        borderRadius: "4px",
        height: "37px",
        "img": {
            cursor: "pointer",
            position: "absolute",
            right: "-4px",
            top: "-4px",
        }
    }),
    multiValueLabel: (base) => ({
        ...base,
        fontSize: "12px",
        color: "#F9F9FC",
        padding: "0",
        paddingLeft: "0"
    }),
};

export default function SetAvailability() {
    const [selectedDate, setSelectedDate] = useState(null);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const datePickerRef = useRef(null);

    const [searchDate, setSearchDate] = useState(null);
    const [isSearchDatePickerOpen, setIsSearchDatePickerOpen] = useState(false);
    const searchDatePickerRef = useRef(null);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [selectedFilterDate, setSelectedFilterDate] = useState("");
    const [availabilityListFiltered, setAvailabilityListFiltered] = useState([]);

    const [selectedTimes, setSelectedTimes] = useState([]);

    const timeOptions = [
        { value: "12:00 A.M.", label: "12:00 A.M." },
        { value: "12:30 A.M.", label: "12:30 A.M." },
        { value: "01:00 A.M.", label: "01:00 A.M." },
        { value: "01:30 A.M.", label: "01:30 A.M." },
        { value: "02:00 A.M.", label: "02:00 A.M." },
        { value: "02:30 A.M.", label: "02:30 A.M." },
        { value: "03:00 A.M.", label: "03:00 A.M." },
        { value: "03:30 A.M.", label: "03:30 A.M." },
        { value: "04:00 A.M.", label: "04:00 A.M." },
        { value: "04:30 A.M.", label: "04:30 A.M." },
        { value: "05:00 A.M.", label: "05:00 A.M." },
        { value: "05:30 A.M.", label: "05:30 A.M." },
        { value: "06:00 A.M.", label: "06:00 A.M." },
        { value: "06:30 A.M.", label: "06:30 A.M." },
        { value: "07:00 A.M.", label: "07:00 A.M." },
        { value: "07:30 A.M.", label: "07:30 A.M." },
        { value: "08:00 A.M.", label: "08:00 A.M." },
        { value: "08:30 A.M.", label: "08:30 A.M." },
        { value: "09:00 A.M.", label: "09:00 A.M." },
        { value: "09:30 A.M.", label: "09:30 A.M." },
        { value: "10:00 A.M.", label: "10:00 A.M." },
        { value: "10:30 A.M.", label: "10:30 A.M." },
        { value: "11:00 A.M.", label: "11:00 A.M." },
        { value: "11:30 A.M.", label: "11:30 A.M." },
        { value: "12:00 P.M.", label: "12:00 P.M." },
        { value: "12:30 P.M.", label: "12:30 P.M." },
        { value: "01:00 P.M.", label: "01:00 P.M." },
        { value: "01:30 P.M.", label: "01:30 P.M." },
        { value: "02:00 P.M.", label: "02:00 P.M." },
        { value: "02:30 P.M.", label: "02:30 P.M." },
        { value: "03:00 P.M.", label: "03:00 P.M." },
        { value: "03:30 P.M.", label: "03:30 P.M." },
        { value: "04:00 P.M.", label: "04:00 P.M." },
        { value: "04:30 P.M.", label: "04:30 P.M." },
        { value: "05:00 P.M.", label: "05:00 P.M." },
        { value: "05:30 P.M.", label: "05:30 P.M." },
        { value: "06:00 P.M.", label: "06:00 P.M." },
        { value: "06:30 P.M.", label: "06:30 P.M." },
        { value: "07:00 P.M.", label: "07:00 P.M." },
        { value: "07:30 P.M.", label: "07:30 P.M." },
        { value: "08:00 P.M.", label: "08:00 P.M." },
        { value: "08:30 P.M.", label: "08:30 P.M." },
        { value: "09:00 P.M.", label: "09:00 P.M." },
        { value: "09:30 P.M.", label: "09:30 P.M." },
        { value: "10:00 P.M.", label: "10:00 P.M." },
        { value: "10:30 P.M.", label: "10:30 P.M." },
        { value: "11:00 P.M.", label: "11:00 P.M." },
        { value: "11:30 P.M.", label: "11:30 P.M." }
    ];


    const [appointmentList, setAppointmentList] = useState([]); // Store availability data
    const [availability, setAvailability] = useState([]); // Store availability data
    const [isDelModalOpen, setIsDelModalOpen] = useState(false); // Modal state
    const [selectedSlot, setSelectedSlot] = useState(null); // Track the selected slot for deletion
    const [selectedDay, setSelectedDay] = useState(null); // Track the selected slot for deletion


    // Fetch initial availability data when component is mounted
    const fetchAvailability = async () => {
        try {
            const responseList = await appointmentsList(); // Pass the date or other relevant identifiers
            if (responseList.data) {
                setAppointmentList(responseList.data);
            }
            const response = await getAvailability(); // Call API to get availability data
            if (Array.isArray(response?.data)) {
                setAvailability(response?.data);
            } else {
                console.error("Expected an array but got", response?.data);
                setAvailability([]); // Fallback to empty array if the data is not an array
            }
        } catch (error) {
            console.error("Error fetching availability data:", error);
            setAvailability([]); // Fallback to empty array on error
        }
    };

    // Call fetchAvailability when the component mounts
    useEffect(() => {
        fetchAvailability();
    }, []);

    useEffect(() => {
        if (searchDate) {
            const filtered = availability.filter((item) =>
                new Date(item.date).toDateString() === new Date(searchDate).toDateString()
            );
            setAvailabilityListFiltered(filtered);
        } else {
            setAvailabilityListFiltered(availability); // Show all items if no date is selected
        }
    }, [searchDate, availability]); // Remove availabilityListFiltered from dependencies

    const filteredTimeOptions = useMemo(() => {
        if (!selectedDate || !availability.length) return timeOptions;

        const selectedDateStr = selectedDate.toISOString().split("T")[0]; // Get date string in YYYY-MM-DD format

        // Find availability for the selected date
        const matchingAvailability = availability.find(
            (item) => new Date(item.date).toISOString().split("T")[0] === selectedDateStr
        );

        if (matchingAvailability && matchingAvailability.timeSlots) {
            const bookedTimes = matchingAvailability.timeSlots.map((slot) => slot.time);

            // Filter out already booked time slots
            return timeOptions.filter((option) => !bookedTimes.includes(option.value));
        }

        return timeOptions; // Return all options if no matching date
    }, [selectedDate, availability]);


    const handleSlotSubmit = async () => {
        if (!selectedDate) {
            toast.error("Please select a valid date.");
            return;
        }
        if (selectedTimes.length === 0) {
            toast.error("Please select at least one time slot.");
            return;
        }

        const payload = {
            date: selectedDate.toISOString(),
            timeSlots: selectedTimes.map(time => ({ time: time.value })),
        };

        try {
            await updateAvailability(payload); // Add new availability via API
            toast.success("Availability set successfully");
            await fetchAvailability(); // Fetch updated availability after adding
            setSelectedDate(null)
            setSelectedTimes([])
        } catch (error) {
            console.error("Error setting availability:", error);
            toast.error("Failed to set availability. Please try again.");
        }
    };

    const handleFilterDateChange = (event) => {

        // if(event.target.value !== ''){
        setSelectedFilterDate(event.target.value);
        // alert(event.target.value)
        // }
    };

    const handleSlotDelete = async () => {
        if (!selectedSlot) return; // Ensure there's a selected slot to delete
        const { day, slot } = selectedSlot;
        // console.log(selectedSlot,appointmentList[0].date,"pp",selectedDay)

        // Check if there are any appointments for the selected slot


        try {
            if (selectedDay) {
                // Deleting a specific time slot
                const data = { specificSlot: selectedDay._id };
                const appointmentsForSlot = appointmentList.filter(appointment => {
                    return appointment?.date === day?.date
                        &&
                        appointment?.time === selectedDay?.time; // Assuming you have day and slot keys in appointments
                });

                if (appointmentsForSlot.length > 0) {
                    toast.warn("There are appointments scheduled for this slot and it cannot be deleted.");
                    setTimeout(() => {
                        closeModal();
                    }, 2000);
                    return; // Prevent deletion if appointments exist
                }
                await deleteAvailabilityTimeSlot(data, selectedSlot?.day?._id);
            } else {
                // Deleting an entire day's availability
                const appointmentsForSlot = appointmentList.filter(appointment => {
                    return appointment?.date === day?.date
                });

                if (appointmentsForSlot.length > 0) {
                    toast.warn("There are appointments scheduled for this day and it cannot be deleted.");
                    setTimeout(() => {
                        closeModal();
                    }, 2000);
                    return; // Prevent deletion if appointments exist
                }
                await deleteAvailability(day._id);
            }

            toast.success("Time slot deleted successfully");
            await fetchAvailability(); // Fetch updated availability after deletion
            closeModal();
        } catch (error) {
            console.error("Error deleting slot:", error);
            toast.error("Failed to delete slot. Please try again.");
        }
    };


    const handleTimeChange = (selectedOptions) => {
        setSelectedTimes(selectedOptions);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setIsDatePickerOpen(false);
    };

    const handleSearchDateChange = (date) => {
        setSearchDate(date);
        setIsSearchDatePickerOpen(false); // Close the datepicker once a date is selected
    };

    const toggleDatePicker = () => {
        setIsDatePickerOpen(!isDatePickerOpen);
    };

    const toggleSearchDatePicker = () => {
        setIsSearchDatePickerOpen(!isSearchDatePickerOpen);
    };


    const openDelModal = (day, slot) => {
        setSelectedSlot({ day }); // Store the selected date and slot
        day && setSelectedDay(slot);
        console.log(slot, "oopk", day)

        setIsDelModalOpen(true);
    };

    const closeModal = () => {
        setIsDelModalOpen(false);
    };

    // Close datepickers if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setIsDatePickerOpen(false);
            }
            if (searchDatePickerRef.current && !searchDatePickerRef.current.contains(event.target)) {
                setIsSearchDatePickerOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <ToastContainer />

            {isDelModalOpen && (
                <DeleteModal
                    closeModal={closeModal}
                    // onConfirm={handleSlotDelete}
                    onConfirm={handleSlotDelete}
                // Call delete slot on confirm
                />
            )}
            <div className="container" style={{ zIndex: "2" }}>
                <div className="set-availability">
                    <h2>Set Availability</h2>
                    <ul className="list-none flex flex-wrap">
                        <li>
                            <label>Date</label>
                            <div className="input_with_calendar availability_input">
                                <input
                                    // type="datetime-local"
                                    onClick={toggleDatePicker}
                                    placeholder="Select Date"
                                    value={selectedDate ? selectedDate.toLocaleDateString() : ""}
                                    readOnly
                                />
                                <img src={calendarIcon} onClick={toggleDatePicker} alt="calendar" />
                                {isDatePickerOpen && (
                                    <div ref={datePickerRef} style={{ position: "absolute", zIndex: 1000 }}>
                                        <DatePicker selected={selectedDate}
                                            onChange={handleDateChange}
                                            minDate={new Date()}
                                            inline />
                                    </div>
                                )}
                            </div>
                        </li>
                        <li>
                            <label>Time</label>
                            <div className={`multi_select ${!selectedDate ? 'disabled' : ''}`}>
                                <Select
                                    options={filteredTimeOptions}
                                    isMulti
                                    isDisabled={!selectedDate}
                                    value={selectedTimes}
                                    onChange={handleTimeChange}
                                    placeholder="Select Time"
                                    styles={customStyles}
                                    components={{ MultiValueRemove: CustomMultiValueRemove }}
                                />
                            </div>
                        </li>
                        <li>
                            <button className="simple_search_btn" onClick={handleSlotSubmit}>
                                Submit
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="table_layout_wrapper">
                <div className="container">
                    <div className="table_title">
                        <h2>Availability Details</h2>
                        <div className="table_search">
                            <form>
                                <ul className="list-none flex">
                                    <li>
                                    <div className="input_with_calendar">
                                            <input
                                                onClick={toggleSearchDatePicker}
                                                placeholder="Search by Date"
                                                value={searchDate ? searchDate.toLocaleDateString("en-US", {
                                                    // timeZone: userTimeZone,
                                                    year: "numeric",
                                                    month: "short",
                                                    day: "2-digit",
                                                }) : ""}
                                                readOnly
                                            />
                                            <img
                                                src={calendarIcon}
                                                alt="calendar"
                                                onClick={toggleSearchDatePicker}
                                            />
                                            {isSearchDatePickerOpen && (
                                                <div
                                                    ref={searchDatePickerRef}
                                                    style={{ position: "absolute", zIndex: 1000 }}
                                                >
                                                    <DatePicker
                                                        selected={searchDate}
                                                        onChange={handleSearchDateChange}
                                                        inline
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                </ul>
                            </form>
                            <div className="pagination_wrap">
                                <h6>{availability.length} Items</h6>
                                <ul className="list-none flex">
                                    <li><button><img src={engleLeft} alt="angle-left" /></button></li>
                                    <li><button className="active">1</button></li>
                                    {/* <li><button>2</button></li> */}
                                    {/* <li><button>...</button></li> */}
                                    <li><button><img src={engleRight} alt="angle-right" /></button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="table_layout">
                        <div className="table_layout_responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Available Slots</th>
                                        <th style={{ width: "95px" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        availabilityListFiltered.length  > 0 ?
                                            (availabilityListFiltered && availabilityListFiltered.map((dataItem, index) => {
                                                const { date, timeSlots } = dataItem;

                                                return (
                                                    <tr key={index}>
                                                        <td style={{whiteSpace: "nowrap"}}>{new Date(date).toLocaleDateString("en-US", {
                                                            timeZone: userTimeZone,
                                                            year: "numeric",
                                                            month: "short", // "long", "short", or "numeric"
                                                            day: "2-digit"
                                                        })}</td>
                                                        <td>
                                                            <div className="slot_wrap">
                                                                {Array.isArray(timeSlots) && timeSlots.length > 0 ? (
                                                                    timeSlots.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).map((slot, idx) => (
                                                                        <div key={`slot-${idx}`} className="slot">  {/* Use `slot.id` as the unique key */}
                                                                            {slot.time} {/* Display the time of the slot */}
                                                                            <img
                                                                                src={slotCross}
                                                                                alt="slot-cross"
                                                                                onClick={() => openDelModal(dataItem, slot)} // Pass the whole `slot` object (including `id` and `time`)
                                                                            />
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <p>No available slots</p>
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="actions">
                                                                <img src={trash} alt="trash" onClick={() => openDelModal(dataItem)} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            ) :

                                            (
                                                <tr>
                                                    <td colSpan="2">No records available.</td>
                                                </tr>
                                            )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
