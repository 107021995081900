import "./gift.scss";
import GiftModal from "../../components/modal/GiftModal";
import { useEffect, useState, useRef } from "react";
import bannerPoster from "../../assets/images/gift_bg.jpeg";
import { getPaymentMethods, giftmessage } from "../../utils/api"; // Add API calls
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Gift() {
  const [isDigitalModalOpen, setIsDigitalModalOpen] = useState(false);
  const [buttonData, setButtonData] = useState({ name: '', email: '' });
  const videoRef = useRef(null); // Ref for the video element
  const [isFormOpen, setIsFormOpen] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [message, setMessage] = useState(""); // Track the message input
  const [loading, setLoading] = useState(false); // Track loading state for the submit button
  const [error, setError] = useState(""); // Track error state for the form

  const openDigitalModal = (name, email) => {
    setButtonData({ name, email });
    setIsDigitalModalOpen(true);
  };

  const closeModal = () => {
    setIsDigitalModalOpen(false);
  };

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Delay to allow the fade effect to be visible

    return () => clearTimeout(timer); // Cleanup timeout
  }, []);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await getPaymentMethods();
        setPaymentMethods(response.data);
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      }
    };

    fetchPaymentMethods();
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    const handleVideoEnded = () => {
      setIsFormOpen(true);
      if (video) {
        video.currentTime = 0; // Reset video to the beginning
      }
    };

    if (video) {
      video.addEventListener('ended', handleVideoEnded);
    }

    return () => {
      if (video) {
        video.removeEventListener('ended', handleVideoEnded);
      }
    };
  }, []);

  const handleSubmit = async () => {
    if (!message.trim()) {
      setError("Please enter a message.");
      return;
    }

    setLoading(true);
    try {
      // Call the API with the message entered by the user
      const response = await giftmessage({ message });
      setLoading(false);
      if (response?.data) {
        // Handle the success case (e.g., show success message, clear form)
        toast.success("Thank you! Your message has been sent.");
        setMessage(""); // Clear the input field after successful submission
        setError(""); // Clear any previous error

        if (videoRef.current) {
            videoRef.current.currentTime = 0; // Reset video time
            videoRef.current.play(); // Play the video
            setIsFormOpen(false);
        }
        
      } else {
        setError("Error sending gift message. Please try again.");
      }
    } catch (err) {
      setLoading(false);
      console.error("Error calling giftmessage API:", err);
      setError("Error sending gift message. Please try again.");
    }
  };

  return (
    <>
    <ToastContainer/>
      {isDigitalModalOpen && <GiftModal closeModal={closeModal} buttonData={buttonData} />}

      <div className={`bg_black_layer_gift ${isVisible ? 'visible' : ''}`}>
        <div className="gift_bg_wrapper">
          <video ref={videoRef} poster={bannerPoster}>
            <source src="https://frontend.development-env.com/book_videos/gift.mp4" type="video/mp4" />
            <source src="https://frontend.development-env.com/book_videos/gift.ogg" type="video/ogg" />
            <source media="(max-width: 600px)" src="https://frontend.development-env.com/book_videos/gift_mobile.mp4" type="video/mp4" />
            <source media="(max-width: 600px)" src="https://frontend.development-env.com/book_videos/gift_mobile.ogg" type="video/ogg" />
          </video>
          <div className="gift_links">
            <ul className="list-none">
              {paymentMethods.map((payment, index) => (
                <li key={index}>
                  <button onClick={() => openDigitalModal(payment?.payment, payment?.details)}>
                    {payment?.payment}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {isFormOpen ? (
            <div className="gift_content">
              <div className="container">
                <section>
                  <input
                    className="user_input"
                    placeholder="PERSONAL MESSAGE"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)} // Update message state on input change
                  />
                  {error && <p className="error-message red_color">❌ {error}</p>}
                  <button
                    className="request_modal"
                    onClick={handleSubmit}
                    disabled={loading} // Disable the button when loading
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </section>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
