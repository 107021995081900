import React from "react";
import { useModal } from "../../contexts/ModalContext";
import OrderPlacedModal from "./OrderPlacedModal";
import PreviouslyRegisteredEmailModal from "./PreviouslyRegisteredEmailModal";

const GlobalModals = () => {
  const { modalType, modalData, closeModal } = useModal(); // Access modal context

  return (
    <>
      {/* Render OrderPlaced modal if modalType is 'OrderPlaced' */}
      {modalType === 'OrderPlaced' && (
        <OrderPlacedModal closeModal={closeModal} data={modalData} />
      )}

      {/* Render PreviouslyRegisteredEmailModal if modalType is 'PreviouslyRegistered' */}
      {modalType === 'PreviouslyRegistered' && (
        <PreviouslyRegisteredEmailModal closeModal={closeModal} />
      )}
    </>
  );
};

export default GlobalModals;