import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PreviouslyRegisteredEmailModal from "./PreviouslyRegisteredEmailModal";
import RequestEmailAlreadyRegisteredModal from "./RequestEmailAlreadyRegisteredModal";


import "./modal.scss";
import closeIcon from "../../assets/images/close.svg";
import { createOrderRequest } from "../../utils/api"; // Ensure createOrderRequest is properly implemented in your API utility

const PhysicalRequestModal = ({ closeModal, openRequestFormModal }) => {
  const [email, setEmail] = useState(""); // State to track user input
  const [error, setError] = useState(null); // State for error messages
  const [emailError, setEmailError] = useState(null); // State for error messages

  const [success, setSuccess] = useState(false); // State to display success message

  const handleSubmit = async () => {
    // alert(email)
    if (!email) {
      // toast.error("Invalid email.")
      setEmailError("Email address is required.");

      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      toast.error("Invalid email.")
      return;
    }

    // Reset error and success messages
    setEmailError(null);
    setError(null);
    setSuccess(false);

    try {
      // Call the API to add the request
      const response = await createOrderRequest({ email });
      if (response && response.status === 201) {
        setSuccess(true);
        toast.success("Your request has been submitted. You will be notified via email when the book becomes available.");

        // openRequestFormModal(); // Optionally open the next modal
        setTimeout(() => {
          closeModal();
        }, 7000);

      } else if (response && response.status === 401) {


      } else {
        setError("Failed to submit the request. Please try again.");

      }
    } catch (err) {
      (err && err.status === 400 &&
        // closeModal();

        // toast.error("Thank you! Your request has been received.");
        setError("Order already placed."))

        ||

        (err && err.status === 401 &&
          // closeModal();

          // toast.error("Thank you! Your request has been received.");
          setError("This email is already registered. You will be notified via email once the book becomes available."))
    }
  };

  return (
    <>
      <ToastContainer />
      {error ?
        (error.includes("Order") ?
          <PreviouslyRegisteredEmailModal closeModal={closeModal} />
          :
          
          <RequestEmailAlreadyRegisteredModal closeModal={closeModal} />
        )
        :
        <div className="modal request_modal_wrap" onClick={closeModal}>
          <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <button type="button" className="close" onClick={closeModal}>
                <img src={closeIcon} alt="close" />
              </button>
              <div className="first_modal">
                <h2>
                  The Physical edition of the book is currently out of stock. If
                  you would like, input your email address and you will be
                  notified when the book becomes available.
                </h2>
                <input
                  className="user_input"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} // Update email state
                />
                {emailError && <p className="error_message">❌ {emailError}</p>} {/* Show error */}
                {/* {success && <p className="success_message">Request submitted successfully!</p>} Show success */}
                <button className="request_modal" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PhysicalRequestModal;
