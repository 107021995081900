import { useState, useEffect } from "react";
import engleLeft from "../../../../assets/images/angle-left.svg";
import engleRight from "../../../../assets/images/angle-right.svg";
import searchIcon from "../../../../assets/images/search.svg";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RejectModal from "../../../../components/modal/RejectModal";
import AcceptModal from "../../../../components/modal/AcceptModal";
import { ordersList, updateOrder } from "../../../../utils/api"; // Importing the API function

export default function PendingOrders() {
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOrderId, setSelectedOrderId] = useState(null); // Track the selected order ID for confirmation
    const [searchQuery, setSearchQuery] = useState(""); // State to hold the search query

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10); // Adjust page size as needed

    const openRejectModal = () => setIsRejectModalOpen(true);
    const openAcceptModal = () => setIsAcceptModalOpen(true);
    const closeModal = () => {
        setIsRejectModalOpen(false);
        setIsAcceptModalOpen(false);
        setSelectedOrderId(null); // Reset selected order ID when closing modals
    };

    // Fetch orders from the API
    const fetchOrders = async () => {
        try {
            const response = await ordersList(); // Make API call
            const pendingOrders = response.data.filter(order => order.status === "Pending"); // Filter only pending orders
            // Sort the orders globally based on createdAt
            pendingOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setOrders(pendingOrders);
            setLoading(false); // Set loading to false after fetching
        } catch (error) {
            console.error("Error fetching orders:", error);
            setLoading(false); // Set loading to false even if there's an error
        }
    };

    // Update order status function
    const updateOrderStatus = async (orderId, status) => {
        try {
            const response = await updateOrder(orderId, { status }); // Call API to update the order status
            if (response.status === 200) {
                // After successful update, fetch updated orders
                fetchOrders();
            }
        } catch (error) {
            console.error(`Error updating order status to ${status}:`, error);
        }
    };

    useEffect(() => {
        fetchOrders(); // Fetch orders when component mounts
    }, []);

    // Calculate paginated orders
    const totalPages = Math.ceil(orders.length / pageSize);
    const paginatedOrders = orders.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    // Filter orders based on search query
    const filteredOrders = paginatedOrders.filter(order =>
        order.orderNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Pagination functions
    const goToPreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const goToNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
    const goToPage = (page) => setCurrentPage(page);

    // Handle Accept button click
    const handleAccept = (orderId) => {
        setSelectedOrderId(orderId); // Store the selected order ID
        openAcceptModal(); // Open the Accept modal
    };

    // Handle Reject button click
    const handleReject = (orderId) => {
        setSelectedOrderId(orderId); // Store the selected order ID
        openRejectModal(); // Open the Reject modal
    };

    // Handle Yes button click in Accept Modal
    const handleAcceptConfirm = () => {
        toast.success("Order has been successfully updated.")
        updateOrderStatus(selectedOrderId, "Fulfilled"); // Update the order status to "fulfilled"
        closeModal(); // Close the modal after confirming
    };

    // Handle Yes button click in Reject Modal
    const handleRejectConfirm = () => {
        toast.success("Order has been successfully updated.")
        updateOrderStatus(selectedOrderId, "Cancelled"); // Update the order status to "cancelled"
        closeModal(); // Close the modal after confirming
    };

    return (
        <>
        <ToastContainer/>
            {isRejectModalOpen && <RejectModal closeModal={closeModal} onConfirm={handleRejectConfirm} />}
            {isAcceptModalOpen && <AcceptModal closeModal={closeModal} onConfirm={handleAcceptConfirm} />}
            <div className="table_layout_wrapper">
                <div className="container">
                    <div className="table_title">
                        <h2>Orders Awaiting Approval</h2>
                        <div className="table_search">
                            <form>
                                <ul className="list-none flex">
                                    <li>
                                        <div className="input_with_calendar">
                                            <input 
                                                placeholder="Search by Order ID" 
                                                type="text" 
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                                            />
                                            <img src={searchIcon} alt="search" className="searchicon" />
                                        </div>
                                    </li>
                                </ul>
                            </form>
                            <div className="pagination_wrap">
                                <h6>{orders.length} Items</h6>
                                <ul className="list-none flex">
                                    <li>
                                        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                                            <img src={engleLeft} alt="angle-left" />
                                        </button>
                                    </li>
                                    {[...Array(totalPages).keys()].map(page => (
                                        <li key={page}>
                                            <button
                                                onClick={() => goToPage(page + 1)}
                                                className={currentPage === page + 1 ? "active" : ""}
                                            >
                                                {page + 1}
                                            </button>
                                        </li>
                                    ))}
                                    <li>
                                        <button onClick={goToNextPage} disabled={currentPage === totalPages}>
                                            <img src={engleRight} alt="angle-right" />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="table_layout">
                        <div className="table_layout_responsive bigtable">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Order</th>
                                        <th>Date</th>
                                        <th>Email</th>
                                        <th style={{ width: "296px" }}>Address</th>
                                        <th>Shipping Date</th>
                                        <th>Approx. Date of Arrival</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td colSpan="7" style={{ textAlign: "center" }}>Loading...</td>
                                        </tr>
                                    ) : (
                                        filteredOrders.length > 0 ? (filteredOrders.map(({  _id, name, orderNumber, address,shippingDate,arrivalDate, email, createdAt }, index) => {
                                            const orderDate = new Date(createdAt).toLocaleDateString("en-US", {
                                                timeZone: userTimeZone,
                                                year: "numeric",
                                                month: "short", // "long", "short", or "numeric"
                                                day: "2-digit"
                                              });

                                            return (
                                                <tr key={_id}>
                                                    <td>{orderNumber} <span style={{textTransform: "capitalize"}}>{name}</span></td>
                                                    <td style={{whiteSpace: "nowrap"}}>{orderDate}</td>
                                                    <td><Link className="innerLink" to={`mailto:${email}`}>{email}</Link></td>
                                                    <td>{address}</td>
                                                    <td>{new Date(shippingDate).toLocaleDateString("en-US", {
                                                        timeZone: userTimeZone,
                                                        year: "numeric",
                                                        month: "short", // "long", "short", or "numeric"
                                                        day: "2-digit"
                                                    })}</td>

                                                    <td>{new Date(arrivalDate).toLocaleDateString("en-US", {
                                                    timeZone: userTimeZone,
                                                    year: "numeric",
                                                    month: "short", // "long", "short", or "numeric"
                                                    day: "2-digit"
                                                })}</td>
                                                    <td>
                                                        <div className="actions">
                                                            <button onClick={() => handleAccept(_id)} className="accept">Accept</button>
                                                            <button onClick={() => handleReject(_id)} className="reject">Reject</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )
                                    :
                                        <tr>
                                            <td colSpan="7" style={{ textAlign: "center" }}>No Record Found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
