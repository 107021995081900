import { Link } from "react-router-dom"
import "./contact.scss"
import mail from "../../assets/images/mail.svg"
import phone from "../../assets/images/phone.svg"
import youtube from "../../assets/images/thumb.jpg"
import youtubeIcon from "../../assets/images/youtube.png"
import { useEffect, useState } from "react";
import ScheduleModal from "../../components/modal/ScheduleModal";

export  default function ContactTwo() {
    const [isPhysicalModalOpen, setIsPhysicalModalOpen] = useState(false);
    const openPhysicalModal = () => setIsPhysicalModalOpen(true);
    const closeModal = () => {
        setIsPhysicalModalOpen(false);
    }

    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        // Set a timeout to change the visibility after component mounts
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 100); // Delay to allow the fade effect to be visible

        return () => clearTimeout(timer); // Cleanup timeout
    }, []);
    return(
        <>
            {isPhysicalModalOpen &&<ScheduleModal closeModal={closeModal}/> }
            <div className={`bg_black_layer ${isVisible ? 'visible' : ''}`}>
                <div className="contact_bg_wrapper">
                    {/* <div className="youtube_channel_wrap">
                        <div className="container">
                            <div className="youtube_channel">
                                <img src={youtube} alt="youtube"/>
                                <section>
                                    <h3>Contemplations for the Tree of Life</h3>
                                    <Link className="request_modal"><img src={youtubeIcon} alt="youtubeIcon"/> Visit YouTube channel</Link>
                                </section>
                            </div>
                        </div>
                    </div> */}
                    <div className="contact_inner_wrapper">
                        <ul className="list-none">
                            <li>
                                <div className="youtube_channel">
                                    <img src={youtube} alt="youtube"/>
                                    <section>
                                        <h3>Contemplations</h3>
                                        <Link to="https://www.youtube.com/@contemplations_tree_of_life" target="_blank" className="request_modal"><img src={youtubeIcon} alt="youtubeIcon"/> Visit YouTube channel</Link>
                                    </section>
                                </div>
                            </li>
                            <li>
                                <Link to="mailto:crisn2blink@gmail.com" className="contact_main_btns">
                                    <img src={mail} alt="mail"/>
                                    crisn2blink@gmail.com
                                </Link>
                            </li>
                            <li>
                                <Link to="tel:(713) 256-3910" className="contact_main_btns">
                                    <img src={phone} alt="phone"/>
                                    (713) 256-3910
                                </Link>
                            </li>
                            <li><button onClick={openPhysicalModal} className="request_modal">SCHEDULE A CALL</button></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}