import React from "react";
import "./modal.scss";
import downloadFile from "../../assets/images/download-file.svg"
import closeIcon from "../../assets/images/close.svg"
import PDF from "../../assets/contemplations-for-the-tree-of-life.pdf"
import EPUB from "../../assets/contemplations-for-the-tree-of-life.epub"

const DigitalModal = ({ closeModal }) => {
  
  return (
    <>
    <div className="modal download_modalWrap" onClick={closeModal}>
        <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
                <button type="button" className="close" onClick={closeModal}>
                  <img src={closeIcon} alt="close"/>
                </button>
                <div className="first_modal">
                    <img src={downloadFile} alt="bookOpen"/>
                    <div className="download_btns">
                      <a href={PDF} download className="request_modal">
                        PDF DOWNLOAD
                      </a>
                      <a href={EPUB} download className="request_modal">
                        EPUB DOWNLOAD
                      </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default DigitalModal;