import "./book.scss";
import bannerPoster from "../../assets/images/book_bg.jpg";
import arrow from "../../assets/images/arrow-left.svg";
import { useEffect, useState } from "react";

export default function TheBook() {
    const [isVisible, setIsVisible] = useState(false);
    const [isSectionVisible, setIsSectionVisible] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const content = [
        <div key={0}>
            The themes of the lives of many individuals are those of suffering & dissatisfaction. This is not a result of life inherently being difficult or working against us, nor does it come about due to the individual willing it. Rather, this situation is the result of the individual lacking certain understanding required to direct their life experience in accordance with their will.
        </div>,
        <div key={1}>
            We live life to the best of our ability, learning <i>from experience but not about</i> experience.
        </div>,
        <div key={2}>
            In order to adequately direct our life experience, we not only need to learn and adapt (learn <i>from</i> experience), but we also need to analyze and understand the process by which our life experience is created (learn <i>about</i> experience). If life was a board game, we are essentially “winging it” without having read the instruction manual first—simply learning as we go.
        </div>,
        <div key={3}>
            By no means is the aim here to discredit the importance of learning from personal experience and that of others; however, we must realize that this is only half of the course material.
        </div>,
        <div key={4}>
            This book grants us the understanding pertaining to how <i>all</i> experience is created (teaches us <i>about</i> experience) and proceeds to walk us through the process of implementing this knowledge so as to achieve the ultimate goal: 
        </div>,
        <div key={5}>
            <b>to gain the capacity to direct our life experience in accordance with our will.</b>
        </div>,
        <div key={6}>
            In this manner, we will put an end to the themes of suffering & dissatisfaction that are predominant in our lives. This book is by no means <i>the</i> understanding by which we can gain this capacity—it is only one path among many.
        </div>
    ];

    useEffect(() => {
        const audio = new Audio('https://frontend.development-env.com/book_videos/single-church.mp3');

        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 100); // Initial visibility

        const sectionTimer = setTimeout(() => {
            setIsSectionVisible(true);
            audio.play(); // Play audio when section is visible
        }, 3000); // Show section after 3 seconds

        return () => {
            clearTimeout(timer);
            clearTimeout(sectionTimer);
            audio.pause(); // Pause audio if the component unmounts
            audio.currentTime = 0; // Reset audio to the beginning
        };
    }, []);

    const handleNext = () => {
        if (currentIndex < content.length - 1) {
            setCurrentIndex(currentIndex + 1);
        } else {
            setIsSectionVisible(false);
        }
    };

    return (
        <>
            <div className={`book_wrapper ${isVisible ? 'visible' : ''}`}>
                {/* <video autoPlay muted loop poster={bannerPoster}> */}
                    {/* <source src="https://frontend.development-env.com/book_videos/banner_video.mp4" type="video/mp4"/> */}
                    {/* <source src="https://frontend.development-env.com/book_videos/banner_video.ogg" type="video/ogg"/> */}
                {/* </video> */}
                <div className={`book_content ${isSectionVisible ? "active": ""}`}>
                    <section>
                        <h2>{content[currentIndex]}</h2>
                        <button className="request_modal" onClick={handleNext}>
                            {currentIndex < content.length - 1 ? "Next" : "Done"}
                            <img src={arrow} alt="arrow-left"/>
                        </button>
                    </section>
                </div>
                <span className="box_wrap">artist: Elettra Cudignotto</span>
            </div>
        </>
    );
}
