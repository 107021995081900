import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { UserLayout } from "./UserLayout";
import { ErrorPage } from "../components/ErrorPage/ErrorPage";
import Home from "../pages/landingpage/Home";
import FreeShop from "../pages/free-shop";
import Contact from "../pages/contact";
import Gift from "../pages/gift";
import TheBook from "../pages/the-book";
import Dashboard from "../pages/admin/dashboard";
import { AdminLayout } from "./AdminLayout";
import Orders from "../pages/admin/orders/all-orders";
import PendingOrders from "../pages/admin/orders/pending-orders";
import DeclinedOrders from "../pages/admin/orders/declined-orders";
import Appointments from "../pages/admin/appointments";
import Shipping from "../pages/admin/shipping";
import PaymentMethods from "../pages/admin/payment-methods";
import SetAvailability from "../pages/admin/set-availability";
import { LoginLayout } from "./LoginLayout";
import Login from "../pages/login";
import ComingSoon from "../pages/comingsoon";
import WaitingList from "../pages/admin/orders/waiting-list";
import CancelledOrders from "../pages/admin/orders/cancelled-orders";
import ContactTwo from "../pages/contact/ContactTwo";

// Simulating an authentication check
const isAdminLoggedIn = () => {
  // Replace with your actual logic to check if the admin is logged in
  if (typeof window !== 'undefined' && localStorage.getItem('admintoken')?.length > 9) 
    {
   return  true;
  }
  return false;
};

// PrivateRoute component to protect admin routes
const PrivateRoute = ({ element }) => {
  return isAdminLoggedIn() ? element : <Navigate to="/login" />;
};

export const MainRouting = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" errorElement={<ErrorPage />} element={<UserLayout />}>
          <Route index element={<Home />} />
          <Route path="/free-shop" element={<FreeShop />} />
          {/* <Route path="/contact" element={<Contact />} /> */}
          <Route path="/contact" element={<ContactTwo />} />
          <Route path="/gift" element={<Gift />} />
          <Route path="/the-book" element={<TheBook />} />
        </Route>

        {/* Admin Route */}
        <Route path="/admin" errorElement={<ErrorPage />} element={<AdminLayout />}>
          <Route index element={<PrivateRoute element={<Dashboard />} />} />
          <Route path="/admin/orders" element={<PrivateRoute element={<Orders />} />} />
          <Route path="/admin/orders/pending-orders" element={<PrivateRoute element={<PendingOrders />} />} />
          <Route path="/admin/orders/declined-orders" element={<PrivateRoute element={<DeclinedOrders />} />} />
          <Route path="/admin/orders/waiting-list" element={<PrivateRoute element={<WaitingList />} />} />
          <Route path="/admin/orders/cancelled-orders" element={<PrivateRoute element={<CancelledOrders />} />} />
          <Route path="/admin/appointments" element={<PrivateRoute element={<Appointments />} />} />
          <Route path="/admin/set-availability" element={<PrivateRoute element={<SetAvailability />} />} />
          <Route path="/admin/shipping" element={<PrivateRoute element={<Shipping />} />} />
          <Route path="/admin/payment-methods" element={<PrivateRoute element={<PaymentMethods />} />} />
        </Route>

        {/* login */}
        <Route path="/login" errorElement={<ErrorPage />} element={<LoginLayout />}>
          <Route index element={<Login />} />
        </Route>

        {/* <Route path="/" errorElement={<ErrorPage />} element={<LoginLayout />}>
          <Route index element={<ComingSoon />} />
        </Route> */}
      </>
    )
  );

  return <RouterProvider router={router} />;
};
