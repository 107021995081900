import "./orders.scss";
import editWhite from "../../../../assets/images/edit.svg";
import engleLeft from "../../../../assets/images/angle-left.svg";
import engleRight from "../../../../assets/images/angle-right.svg";
import greenCheck from "../../../../assets/images/greencheck.svg";
import searchIcon from "../../../../assets/images/search.svg";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ordersList, updateOrder } from "../../../../utils/api"; // Assuming updateOrder is a function to update order status

export default function Orders() {
    const [showInputIndex, setShowInputIndex] = useState(null); // To track which row's status is being edited
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("All");
    const [loading, setLoading] = useState(true);
    const [orderStatuses, setOrderStatuses] = useState({}); // Object to store status per order
    const [searchQuery, setSearchQuery] = useState(""); // State for search query

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Set items per page

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


    // Fetch orders from API
    const fetchOrders = async () => {
        try {
            const response = await ordersList();
            setOrders(response.data.filter(x => { return x.status === ("Fulfilled")}));
            setLoading(false);
        } catch (error) {
            console.error("Error fetching orders:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    useEffect(() => {
        let filtered = orders;
    
        // Filter orders based on selected status
        if (selectedStatus === "Fulfilled") {
            filtered = filtered.filter(order => order.delivery === selectedStatus);
        } else if (selectedStatus === "Unfulfilled") {
            filtered = filtered.filter(order => order.delivery === selectedStatus);
        } else if (selectedStatus === "Cancelled") {
            filtered = filtered.filter(order => order.delivery === selectedStatus);
        }
    
        // Filter orders based on search query
        if (searchQuery) {
            filtered = filtered.filter(order => order.orderNumber.toLowerCase().includes(searchQuery.toLowerCase()));
        }
    
        // Apply sorting by created date (descending) before pagination
        filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    
        setFilteredOrders(filtered);
        setCurrentPage(1); // Reset to first page on filter or search change
    }, [orders, selectedStatus, searchQuery]);
    

    // Handle the input toggle (edit or confirm)
    const toggleInput = (index) => {
        setShowInputIndex(showInputIndex === index ? null : index);
    };

    // Handle status change for a specific order
    const handleDeliveryChange = (orderId, newStatus) => {
        setOrderStatuses(prevStatuses => ({
            ...prevStatuses,
            [orderId]: newStatus
        }));
    };

    // Update the order status via API
    const handleDeliveryUpdate = async (orderId) => {
        const newStatus = orderStatuses[orderId];
        if (newStatus) {
            try {
                const response = await updateOrder(orderId, { delivery: newStatus });
                if (response.status === 200) {
                    fetchOrders(); // Fetch updated orders after successful update
                    setShowInputIndex(null); // Close the edit input after update
                    toast.success("Delivery status has been successfully updated.")
                }
            } catch (error) {
                console.error("Error updating order:", error);
            }
        }
    };

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentOrders = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
        <ToastContainer />
        
        <div className="table_layout_wrapper">
            <div className="container">
                <div className="table_title">
                    <h2>View Orders</h2>
                    <div className="table_search">
                        <form>
                            <ul className="list-none flex">
                                <li>
                                    <select
                                        value={selectedStatus}
                                        onChange={(e) => setSelectedStatus(e.target.value)}
                                    >
                                        <option value="All">All</option>
                                        <option value="Fulfilled">Fulfilled</option>
                                        <option value="Unfulfilled">Unfulfilled</option>
                                        <option value="Cancelled">Cancelled</option>
                                    </select>
                                </li>
                                <li>
                                    <div className="input_with_calendar">
                                        <input
                                            placeholder="Search by Order ID"
                                            type="text"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                                        />
                                        <img src={searchIcon} alt="search" className="searchicon" />
                                    </div>
                                </li>
                            </ul>
                        </form>
                        <div className="pagination_wrap">
                            <h6>{filteredOrders.length} Items</h6>
                            <ul className="list-none flex">
                                <li><button onClick={handlePreviousPage} disabled={currentPage === 1}><img src={engleLeft} alt="angle-left" /></button></li>
                                {[...Array(totalPages)].map((_, idx) => (
                                    <li key={idx}>
                                        <button
                                            className={currentPage === idx + 1 ? "active" : ""}
                                            onClick={() => handlePageClick(idx + 1)}
                                        >
                                            {idx + 1}
                                        </button>
                                    </li>
                                ))}
                                <li><button onClick={handleNextPage} disabled={currentPage === totalPages}><img src={engleRight} alt="angle-right" /></button></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="table_layout">
                    <div className="table_layout_responsive bigtable">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Date</th>
                                    <th>Email</th>
                                    <th style={{ width: "296px" }}>Address</th>
                                    <th>Shipping Date</th>
                                    <th>Approx. Date of Arrival</th>
                                    <th>Delivery Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="7" style={{ textAlign: "center" }}>Loading...</td>
                                    </tr>
                                ) : 
                                currentOrders.length > 0 ?
                                (
                                    currentOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(({ _id, orderNumber, name, email, address, delivery,shippingDate,arrivalDate, createdAt }, index) => {
                                        const orderDate = new Date(createdAt).toLocaleDateString
                                        ("en-US", {
                                            timeZone: userTimeZone,
                                            year: "numeric",
                                            month: "short", // "long", "short", or "numeric"
                                            day: "2-digit"
                                          });

                                        return (
                                            <tr key={_id}>
                                                <td>{orderNumber} {name.replace(/\b\w/g, char => char.toUpperCase())}</td>
                                                <td style={{whiteSpace: "nowrap"}}>{orderDate}</td>
                                                <td>
                                                    <Link className="innerLink" to={`mailto:${email}`}>{email}</Link>
                                                </td>
                                                <td>{address}</td>
                                                <td>{new Date(shippingDate).toLocaleDateString("en-US", {
                                                timeZone: userTimeZone,
                                                year: "numeric",
                                                month: "short", // "long", "short", or "numeric"
                                                day: "2-digit"
                                              })}</td>
                                                    <td>{new Date(arrivalDate).toLocaleDateString("en-US", {
                                               timeZone: userTimeZone,
                                                year: "numeric",
                                                month: "short", // "long", "short", or "numeric"
                                                day: "2-digit"
                                              })}</td>
                                                <td>
                                                    <div className="actions">
                                                        {showInputIndex === index ? (
                                                            <select
                                                                value={orderStatuses[_id] || delivery}
                                                                onChange={(e) => handleDeliveryChange(_id, e.target.value)}
                                                            >
                                                                <option value="Fulfilled">Fulfilled</option>
                                                                <option value="Unfulfilled">Unfulfilled</option>
                                                                <option value="Cancelled">Cancelled</option>
                                                            </select>
                                                        ) : (
                                                            delivery
                                                        )}
                                                        <img
                                                            onClick={() => {
                                                                if (showInputIndex === index) {
                                                                    handleDeliveryUpdate(_id); // Update order status if user confirmed
                                                                }
                                                                toggleInput(index);
                                                            }}
                                                            src={showInputIndex === index ? greenCheck : editWhite}
                                                            alt="edit"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                )
                                : (
                                    <tr>
                                       <td colSpan="7" style={{ textAlign: "center" }}>No Record Found</td>
                                    </tr>
                                  )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
}
