import "./modal.scss";
import bookOpen from "../../assets/images/book-open.svg"
import closeIcon from "../../assets/images/close.svg"
// import { useEffect, useState } from "react";
import { getInventory, getShipping } from "../../utils/api"; // Assuming getInventory function is imported here.



const PhysicalModal = ({ closeModal, openPhysicalRequestModal,openRequestFormModal, isOpen }) => {

  // useEffect(() => {
    const fetchInventory = async () => {
        try {
            const response = await getInventory(); // Assuming this API gives the response structure
            const responseShipping = await getShipping();

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0); // Reset time portion to ensure comparison is only by date

            let shippingFutureDate;

            let highestDate = null;

            // Loop through all shipping records to find the highest shipping date
            for (let shipping of responseShipping?.data) {
                if (shipping.shippingDate && shipping.shippingDate.length > 0) {
                    // Get the highest date from the current shipping record
                    const recordHighestDate = new Date(Math.max(
                        ...shipping.shippingDate.map(dateObj => new Date(dateObj.date).getTime())
                    ));
            
                    // If it's the first record or higher than the previous highest date, update highestDate
                    if (!highestDate || recordHighestDate > highestDate) {
                        highestDate = recordHighestDate;
                    }
                }
            }
            
            // Now compare the highest shipping date with the current date
             shippingFutureDate = highestDate && highestDate > currentDate;
            


            
            if (response.data && response.data.physicalDelivery !== undefined)  {
                // setPhysicalDelivery(response.data.physicalDelivery); // Update state with physicalDelivery value
                if (response.data.physicalDelivery && response.data.quantity > 0 && shippingFutureDate)  {
                  openRequestFormModal();
                  
                    // setIsPhysicalRequestModalOpen(response.data.physicalDelivery); // Open Physical Modal if physicalDelivery is true
                }else{
                  openPhysicalRequestModal();
                  // closeModal();


                }
            }
        } catch (error) {
            console.error("Error fetching inventory:", error);
        }
    };

    // fetchInventory(); // Fetch inventory on component mount
// }, []);
  
  
  return (
    <>
      <div className={`modal ${isOpen ? 'show' : ''}`} onClick={closeModal}>
          <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
              <div className="modal-content">
                  <button type="button" className="close" onClick={closeModal}>
                    <img src={closeIcon} alt="close"/>
                  </button>
                  <div className="first_modal">
                      <img src={bookOpen} alt="bookOpen"/>
                      <button
                        className="request_modal"
                        onClick={()=>{
                          // openPhysicalRequestModal();
                          fetchInventory();
                          closeModal()
                        }}
                      >REQUEST
                      </button>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
};

export default PhysicalModal;