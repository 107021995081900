import React from "react";
import "./modal.scss";
import closeIcon from "../../assets/images/close.svg"

const GiftModal = ({ closeModal, buttonData  }) => {
  
  return (
    <>
    <div className="modal gift_modal_wrap" onClick={closeModal}>
        <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
                <button type="button" className="close" onClick={closeModal}>
                  <img src={closeIcon} alt="close"/>
                </button>
                <div className="first_modal">
                    <h2>{buttonData.name}</h2>
                    <p dangerouslySetInnerHTML={{ __html: buttonData.email }} />
                    <button onClick={closeModal} className="request_modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default GiftModal;