import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const useModal = () => {
    return useContext(ModalContext);
};

export const ModalProvider = ({ children }) => {
    const [modalType, setModalType] = useState(null);
    const [modalData, setModalData] = useState(null);

    const openModal = (type, data) => {
        setModalType(type);
        setModalData(data);
    };

    const closeModal = () => {
        setModalType(null);
        setModalData(null);
    };

    return (
        <ModalContext.Provider value={{ modalType, modalData, openModal, closeModal }}>
            {children}
        </ModalContext.Provider>
    );
};
