import React, { useEffect, useRef, useState } from "react";

import "./modal.scss";
import closeIcon from "../../assets/images/close.svg"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OrderPlacedModal = ({ closeModal, data }) => {
  // orderPlacedData
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


//   useEffect(() => {
//     data && toast.success("Your order has been placed successfully!")

//     // return () => clearTimeout(timer); // Cleanup timeout
// }, [data.name]);



  
  return (
    <>
    <ToastContainer/>
      <div className="modal request_modal_wrap orderplaced_wrap" onClick={closeModal}>
          <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
              <div className="modal-content">
                  <button type="button" className="close" onClick={closeModal}>
                    <img src={closeIcon} alt="close"/>
                  </button>
                  <div className="first_modal">
                      <h2>ORDER HAS BEEN PLACED</h2>
                      <ul className="list-none">
                        <li><span>Name:</span> {data?.name} </li>
                        <li><span>ITEM:</span> {data?.item}</li>
                        <li><span>SHIPPING DATE:</span> {new Date (data?.shippingDate).toLocaleDateString("en-US", {
                                            timeZone: userTimeZone,
                                            year: "numeric",
                                            month: "short", // "long", "short", or "numeric"
                                            day: "2-digit"
                                          })}</li>
                        <li><span>APPROX. DATE OF ARRIVAL:</span> {new Date (data?.arrivalDate).toLocaleDateString("en-US", {
                                            timeZone: userTimeZone,
                                            year: "numeric",
                                            month: "short", // "long", "short", or "numeric"
                                            day: "2-digit"
                                          })}</li>
                      </ul>
                      <button onClick={closeModal} className="request_modal">OK</button>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
};

export default OrderPlacedModal;