import { useState, useEffect } from "react";
import engleLeft from "../../../../assets/images/angle-left.svg";
import engleRight from "../../../../assets/images/angle-right.svg";
import searchIcon from "../../../../assets/images/search.svg";
import { Link } from "react-router-dom";
import AcceptModal from "../../../../components/modal/AcceptModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ordersList, updateOrder } from "../../../../utils/api"; // Importing the API function

export default function DeclinedOrders() {
    const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
    const [declinedOrders, setDeclinedOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]); // For search results
    const [searchTerm, setSearchTerm] = useState(""); // Search input state
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [ordersPerPage, setOrdersPerPage] = useState(10);

    const [selectedOrderId, setSelectedOrderId] = useState(null);

    const openAcceptModal = () => setIsAcceptModalOpen(true);
    const closeModal = () => setIsAcceptModalOpen(false);

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


    const fetchDeclinedOrders = async () => {
        try {
            const response = await ordersList();
            const declinedOrders = response.data.filter(order => order.status === "Cancelled");
            setDeclinedOrders(declinedOrders);
            setFilteredOrders(declinedOrders); // Set both for initial rendering
            setLoading(false);
        } catch (error) {
            console.error("Error fetching declined orders:", error);
            setLoading(false);
        }
    };

    const handleOrderUpdate = async () => {
        if (selectedOrderId) {
            try {
                const response = await updateOrder(selectedOrderId, { status: "Fulfilled" });
                if (response.status === 200) {
                    toast.success("Order has been successfully updated.");
                    fetchDeclinedOrders();
                    closeModal();
                }
            } catch (error) {
                console.error("Error updating order status:", error);
            }
        }
    };

    useEffect(() => {
        fetchDeclinedOrders();
    }, []);

    const handleSearchChange = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        if (term) {
            const results = declinedOrders.filter(order =>
                order.orderNumber.toLowerCase().includes(term) ||
                order.email.toLowerCase().includes(term) ||
                order.name.toLowerCase().includes(term)
            );
            setFilteredOrders(results);
        } else {
            setFilteredOrders(declinedOrders); // Reset to all orders when search is cleared
        }
        setCurrentPage(1); // Reset to the first page
    };

    const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);
    const sortedOrders = [...filteredOrders].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    const currentOrders = sortedOrders.slice((currentPage - 1) * ordersPerPage, currentPage * ordersPerPage);
    
    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const handleAcceptButtonClick = (orderId) => {
        setSelectedOrderId(orderId);
        openAcceptModal();
    };

    return (
        <>
            <ToastContainer />
            {isAcceptModalOpen && (
                <AcceptModal 
                    closeModal={closeModal} 
                    onConfirm={handleOrderUpdate}
                />
            )}
            <div className="table_layout_wrapper">
                <div className="container">
                    <div className="table_title">
                        <h2>Declined Orders</h2>
                        <div className="table_search">
                            <form>
                                <ul className="list-none flex">
                                    <li>
                                        <div className="input_with_calendar">
                                            <input
                                                placeholder="Search by Order ID"
                                                type="text"
                                                value={searchTerm}
                                                onChange={handleSearchChange}
                                            />
                                            <img src={searchIcon} alt="search" className="searchicon" />
                                        </div>
                                    </li>
                                </ul>
                            </form>
                            <div className="pagination_wrap">
                                <h6>{filteredOrders.length} Items</h6>
                                <ul className="list-none flex">
                                    <li>
                                        <button
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        >
                                            <img src={engleLeft} alt="angle-left" />
                                        </button>
                                    </li>
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <li key={index}>
                                            <button
                                                onClick={() => handlePageChange(index + 1)}
                                                className={currentPage === index + 1 ? "active" : ""}
                                            >
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                    <li>
                                        <button
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                        >
                                            <img src={engleRight} alt="angle-right" />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="table_layout">
                        <div className="table_layout_responsive bigtable">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Order</th>
                                        <th>Date</th>
                                        <th>Email</th>
                                        <th style={{ width: "296px" }}>Address</th>
                                        <th>Shipping Date</th>
                                        <th>Approx. Date of Arrival</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td colSpan="7" style={{ textAlign: "center" }}>Loading...</td>
                                        </tr>
                                    ) : (
                                        currentOrders.length > 0 ? (
                                            currentOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(({ _id, name, orderNumber,shippingDate,arrivalDate, address, email, createdAt }) => {
                                                const orderDate = new Date(createdAt).toLocaleDateString("en-US", {
                                                    timeZone: userTimeZone,
                                                    year: "numeric",
                                                    month: "short", // "long", "short", or "numeric"
                                                    day: "2-digit"
                                                  });
                                                return (
                                                    <tr key={_id}>
                                                        <td>{orderNumber} {name}</td>
                                                        <td style={{whiteSpace: "nowrap"}}>{orderDate}</td>
                                                        <td><Link className="innerLink" to={`mailto:${email}`}>{email}</Link></td>
                                                        <td>{address}</td>
                                                        <td>{new Date(shippingDate).toLocaleDateString("en-US", {
                                               timeZone: userTimeZone,
                                                year: "numeric",
                                                month: "short", // "long", "short", or "numeric"
                                                day: "2-digit"
                                              })}</td>
                                                    <td>{new Date(arrivalDate).toLocaleDateString("en-US", {
                                                timeZone: userTimeZone,
                                                year: "numeric",
                                                month: "short", // "long", "short", or "numeric"
                                                day: "2-digit"
                                              })}</td>
                                                        <td>
                                                            <div className="actions">
                                                                <button onClick={() => handleAcceptButtonClick(_id)} className="accept">Accept</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="7" style={{ textAlign: "center" }}>No Record Found</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
