import React from "react";
import "./modal.scss";
import closeIcon from "../../assets/images/close.svg"

const PreviouslyRegisteredEmailModal = ({ closeModal }) => {
  
  return (
    <>
      <div className="modal request_modal_wrap" onClick={closeModal}>
          <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
              <div className="modal-content">
                  <button type="button" className="close" onClick={closeModal}>
                    <img src={closeIcon} alt="close"/>
                  </button>
                  <div className="first_modal">
                      <h2>This email or address has been previously registered. There is a limit of one Physical edition of the book per household. For an exception, please contact the author directly.</h2>
                      <button
                        style={{marginTop: "0"}}
                        className="request_modal"
                        onClick={()=>{
                          closeModal()
                        }}
                      >Ok
                      </button>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
};

export default PreviouslyRegisteredEmailModal;