import paymentLogo1 from "../../../assets/images/payment-logo1.svg"
import paymentLogo2 from "../../../assets/images/payment-logo2.svg"
import paymentLogo3 from "../../../assets/images/payment-logo3.svg"
import paymentLogo4 from "../../../assets/images/payment-logo4.svg"
import edit from "../../../assets/images/edit.svg"
import greenCheck from "../../../assets/images/greencheck.svg"
import { useState,useEffect } from "react"
import { getPaymentMethods, updatePaymentMethods } from "../../../utils/api"; // Add API calls
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function PaymentMethods() {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const [updatedDetails, setUpdatedDetails] = useState({});

    // Fetch payment methods on component mount
    useEffect(() => {
        const fetchPaymentMethods = async () => {
            try {
                const response = await getPaymentMethods();
                setPaymentMethods(response.data);
            } catch (error) {
                console.error("Error fetching payment methods:", error);
            }
        };
        
        fetchPaymentMethods();
    }, []);

    // Handle toggling the edit mode for each payment method
    const handleToggleEdit = (index) => {
        if (activeIndex === index) {
            // If clicking on the already active index, we should update the payment method
            handleUpdate(index);
        } else {
            // If clicking on a new payment method, enter edit mode
            setActiveIndex(index);
        }
    };

    // Handle change in payment details
    const handleChange = (e, index) => {

        // const value = e.target.value;

        
        setUpdatedDetails({
            ...updatedDetails,
            [index]: e.target.value
        });
    };

    // Handle updating payment method
    const handleUpdate = async (index) => {
        try {

            if (updatedDetails[index].trim() === "" || paymentMethods[index].details.trim() === ""){
                toast.error("Please fill out this field.");
    return;
            }
    
            const updatedData = {
                payment: paymentMethods[index].payment,  // Send the payment type to identify the method
                details: updatedDetails[index] || paymentMethods[index].details,
            };

            await updatePaymentMethods(updatedData);
            // Update the paymentMethods state to reflect changes
            setPaymentMethods(prev => {
                const newMethods = [...prev];
                newMethods[index] = { ...newMethods[index], details: updatedData.details };
                return newMethods;
            });
            toast.success("Payment detail has been successfully updated.");

            setActiveIndex(null); // Exit edit mode after update
        } catch (error) {
            console.error("Error updating payment method:", error);
        }
    };

    return (
        <>
      <ToastContainer />

        <div className="payment_wrapper">
            <div className="container">
                <section>
                    <h2>Payment Method Details</h2>
                    <ul className="list-none">
                        {paymentMethods.map((method, index) => (
                            <li key={index}>
                                <figure>
                                    <img
                                        src={index === 0 ? paymentLogo1 : index === 1 ? paymentLogo2 : index === 2 ? paymentLogo3 : paymentLogo4}
                                        alt={`payment-logo${index + 1}`}
                                    />
                                </figure>
                                <p>
                                    {activeIndex === index ? (
                                       <input
                                       type="text"
                                       required
                                       value={updatedDetails[index] !== undefined || updatedDetails[index]?.trim().length > 0 ? updatedDetails[index] : method.details}
                                       onChange={(e) => handleChange(e, index)}
                                       placeholder="Enter Payment Detail"
                                   />
                                    ) : (
                                        <span>{method.details}</span>
                                    )}
                                </p>
                                <img
                                    onClick={() => handleToggleEdit(index)}
                                    src={activeIndex === index ? greenCheck : edit}
                                    alt="edit"
                                />
                            </li>
                        ))}
                    </ul>
                </section>
            </div>
        </div>

        </>
    );
}