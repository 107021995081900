

// utils/api.js

import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

console.log(process.env.REACT_APP_API_URL, "oiop")


// Uesrs base url
const booksApi = axios.create({
  baseURL: `${apiUrl}/books/`,

  headers: {
    'Content-Type': 'application/json',
    Authorization: typeof window !== 'undefined' && localStorage.getItem('token') != null && `Bearer ${localStorage.getItem('token')}` ,

    // Add any other necessary headers here, such as Authorization
  },
});

// GENERIC RQUESTS
const baseApi = axios.create({
  baseURL: `${apiUrl}/`,

  headers: {
    'Content-Type': 'application/json',
    Authorization: typeof window !== 'undefined' && localStorage.getItem('token') != null && `Bearer ${localStorage.getItem('token')}` ,

    // Add any other necessary headers here, such as Authorization
  },
});


// Admin base url
const adminApi = axios.create({
    baseURL: `${apiUrl}/admin/`,

    headers: {
      'Content-Type': 'application/json',
      Authorization: typeof window !== 'undefined' && localStorage.getItem('admintoken') != null && `Bearer ${localStorage.getItem('admintoken')}` ,

      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklkIjoiNjZmNTJiNWEwNjFjYWJmMjczZDE5MjIxIiwiaWF0IjoxNzI5Nzc3MDQxLCJleHAiOjE3Mjk3ODA2NDF9.W437rraCgyQ1TjWJsiZ6QabsPb5CQoeBOS_KTDQ7kEw`,
    },
  });

  const ordersApi = axios.create({
    baseURL: `${apiUrl}/orders/`,

    headers: {
      'Content-Type': 'application/json',
      Authorization: typeof window !== 'undefined' && localStorage.getItem('admintoken') != null && `Bearer ${localStorage.getItem('admintoken')}` ,

      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklkIjoiNjZmNTJiNWEwNjFjYWJmMjczZDE5MjIxIiwiaWF0IjoxNzI5Nzc3MDQxLCJleHAiOjE3Mjk3ODA2NDF9.W437rraCgyQ1TjWJsiZ6QabsPb5CQoeBOS_KTDQ7kEw`,
    },
  });


//user api calls

export const login = (data) => booksApi.post('/login', data);

export const adminLogin = (data) => adminApi.post('/login', data);






//order api calls

// export const login = (data) => ordersApi.post('/orders', data);


export const updateOrder = (id, data) => baseApi.put(`/orders/orderid/${id}`,data);





//admin api calls

export const ordersList = () => adminApi.get(`/orders`);


export const updateAvailability = (data) => adminApi.post(`/availability`,data);
export const getAvailability = () => adminApi.get(`/availability`);
export const deleteAvailability = (id) => adminApi.delete(`/availability/${id}`);
export const deleteAvailabilityTimeSlot = (data,id) => adminApi.patch(`/availabilitytimeslot/${id}`,data);

export const getInventory = () => adminApi.get('/getinventory');
export const updateInventory = (data) => adminApi.post('/updateinventory',data);
export const getPaymentMethods = (data) => adminApi.get('/payment-methods',data);
export const updatePaymentMethods = (data) => adminApi.post('/payment-methods',data);

export const getShipping = () => adminApi.get('/shipping');

export const addShipping = (data) => adminApi.post('/shipping',data);

export const deleteShipping = (id,date) => adminApi.post(`/shipping/${id}`,date);







// gift

export const giftmessage = (data) => baseApi.post('/orders/giftmessage',data);








// router.post('/availability', setAvailability); // Set availability





//Base api appointments calls

export const appointmentsList = () => baseApi.get(`/appointments`);
export const createAppointment = (data) => baseApi.post(`/appointments`,data);
export const updateAppointment = (id,data) => baseApi.put(`/appointments/${id}`,data);


//Base api order calls

export const createOrder = (data) => baseApi.post('/orders/create',data);
export const createOrderRequest = (data) => baseApi.post('/orders/addrequest',data);
export const getAllOrderRequests = () => baseApi.get('/orders/allrequests');









// Fetch order list stats (fulfilled, pending, requested)


// Update book stock
export const updateBookStock = (newStock) => {
  return adminApi.put('/books/stock', { stock: newStock });
};

// Toggle physical delivery status
export const togglePhysicalDelivery = (isEnabled) => {
  return adminApi.put('/settings/delivery-status', { isPhysicalDeliveryEnabled: isEnabled });
};



