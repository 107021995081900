import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import "../assets/styles/userfontglobals.scss"
import { ModalProvider } from "../contexts/ModalContext";
import GlobalModals from "../components/modal/GlobalModals";

export const UserLayout = () => {
    return (
        <div className="userlayout">
            <ModalProvider>
                <Header/>
                <Outlet />
                <Footer/>
                <GlobalModals />
            </ModalProvider>
        </div>
    );
};
