import { useState, useEffect } from "react";
import engleLeft from "../../../../assets/images/angle-left.svg";
import engleRight from "../../../../assets/images/angle-right.svg";
import searchIcon from "../../../../assets/images/search.svg";
import { Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getAllOrderRequests } from "../../../../utils/api"; // Importing the API function

export default function WaitingList() {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10); // Adjust page size as needed

    // Search state
    const [searchTerm, setSearchTerm] = useState("");

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


    // Fetch orders from the API
    const fetchOrders = async () => {
        try {
            const response = await getAllOrderRequests(); // Make API call
            const fetchedOrders = response.data.data;
    
            // Sort fetched orders by date (most recent first)
            const sortedOrders = fetchedOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setOrders(sortedOrders);
            setLoading(false); // Set loading to false after fetching
        } catch (error) {
            console.error("Error fetching orders:", error);
            setLoading(false); // Set loading to false even if there's an error
        }
    };

    useEffect(() => {
        fetchOrders(); // Fetch orders when component mounts
    }, []);

    // Filter orders based on the search term
    const filteredOrders = orders.filter((order) =>
        order._id.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    const totalPages = Math.ceil(filteredOrders.length / pageSize);
    const paginatedOrders = filteredOrders.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    // Pagination functions
    const goToPreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
    const goToNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    const goToPage = (page) => setCurrentPage(page);

    return (
        <>
            <ToastContainer />
            <div className="table_layout_wrapper">
                <div className="container">
                    <div className="table_title">
                        <h2>Waiting List</h2>
                        <div className="table_search">
                            <form>
                                <ul className="list-none flex">
                                    <li>
                                        <div className="input_with_calendar">
                                            <input
                                                placeholder="Search by Order ID"
                                                type="text"
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                            <img src={searchIcon} alt="search" className="searchicon" />
                                        </div>
                                    </li>
                                </ul>
                            </form>
                            <div className="pagination_wrap">
                                <h6>{filteredOrders.length} Items</h6>
                                <ul className="list-none flex">
                                    <li>
                                        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                                            <img src={engleLeft} alt="angle-left" />
                                        </button>
                                    </li>
                                    {[...Array(totalPages).keys()].map((page) => (
                                        <li key={page}>
                                            <button
                                                onClick={() => goToPage(page + 1)}
                                                className={currentPage === page + 1 ? "active" : ""}
                                            >
                                                {page + 1}
                                            </button>
                                        </li>
                                    ))}
                                    <li>
                                        <button onClick={goToNextPage} disabled={currentPage === totalPages}>
                                            <img src={engleRight} alt="angle-right" />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="table_layout">
                        <div className="table_layout_responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Order</th>
                                        <th>Date</th>
                                        <th>Email</th>
                                        <th>Email Sent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td colSpan="4" style={{ textAlign: "center" }}>Loading...</td>
                                        </tr>
                                    ) : paginatedOrders.length === 0 ? (
                                        <tr>
                                            <td colSpan="4" style={{ textAlign: "center" }}>No records found.</td>
                                        </tr>
                                    ) : (
                                        paginatedOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((order) => (
                                            <tr key={order._id}>
                                                <td>{order._id.slice(-4)}</td>
                                                <td>{new Date(order.createdAt).toLocaleDateString("en-US", {
                                            timeZone: userTimeZone,
                                            year: "numeric",
                                            month: "short", // "long", "short", or "numeric"
                                            day: "2-digit"
                                          })}</td>
                                                <td>
                                                    <Link className="innerLink" to={`mailto:${order.email}`}>
                                                        {order.email}
                                                    </Link>
                                                </td>
                                                <td>{order.emailsent}</td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
