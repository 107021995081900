import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import "./sidebar.scss";
import { NavLink, useLocation } from 'react-router-dom';
import dashboardIcon from "../../assets/images/dashboardIcon.svg";
import dashboardIconActive from "../../assets/images/activedashboardIcon.svg";
import ordersIcon from "../../assets/images/orders.svg";
import scheduleIcon from "../../assets/images/schedule.svg";
import shippingIcon from "../../assets/images/shipping.svg";
import shippingIconActive from "../../assets/images/activeshipping.svg";
import paymentIcon from "../../assets/images/payment.svg";
import paymentIconActive from "../../assets/images/activepayment.svg";
import { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

export default function AdminSidebar() {
    const location = useLocation();
    const [showMenu, setShowMenu] = useState(false);
    const [openSubMenu, setOpenSubMenu] = useState('');

    // Check local storage on mount
    useEffect(() => {
        const menuState = localStorage.getItem('sidebarMenuState');
        if (menuState === 'true') {
            setShowMenu(true);
        }
        // Determine if a submenu should be open based on the current location
        if (location.pathname.includes('/admin/appointments') || location.pathname.includes('/admin/set-availability')) {
            setOpenSubMenu('scheduleCalls');
        } else if (location.pathname.includes('/admin/orders')) {
            setOpenSubMenu('orders');
        }
    }, [location.pathname]);

    const openMenu = () => {
        setShowMenu(prev => {
            const newState = !prev;
            localStorage.setItem('sidebarMenuState', newState);
            return newState;
        });
    };

    const handleSubMenuToggle = (submenu) => {
        setOpenSubMenu(prev => (prev === submenu ? '' : submenu));
    };

    return (
        <>
            <OutsideClickHandler
                onOutsideClick={() => {
                    setShowMenu(false);
                    localStorage.setItem('sidebarMenuState', 'false');
                }}
            >
                <button onClick={openMenu} className={`menu_btn sidebar_button ${showMenu ? "active" : ''}`}>
                    <span></span>
                </button>
                <div className={`sidebar_wrap ${showMenu ? "active" : ''}`}>
                    <Sidebar>
                        <Menu>
                            <MenuItem
                                onClick={() => {
                                    setShowMenu(false);
                                    localStorage.setItem('sidebarMenuState', 'false');
                                }}
                                icon={
                                    <NavLink end to="/admin" activeClassName="active">
                                        {({ isActive }) => (
                                            <img
                                                src={isActive ? dashboardIconActive : dashboardIcon}
                                                alt="Dashboard"
                                            />
                                        )}
                                    </NavLink>
                                }
                                component={<NavLink end activeClassName="active" to="/admin" />}> Dashboard
                            </MenuItem>
                            <SubMenu 
                                icon={<img src={ordersIcon} alt="orders" />} 
                                label="Orders" 
                                open={openSubMenu === 'orders'}
                                onClick={() => handleSubMenuToggle('orders')}
                            >
                                <MenuItem onClick={() => setShowMenu(false)} component={<NavLink end activeClassName="active" to="/admin/orders" />}> View Orders</MenuItem>
                                <MenuItem onClick={() => setShowMenu(false)} component={<NavLink end activeClassName="active" to="/admin/orders/pending-orders" />}> Orders Awaiting Approval</MenuItem>
                                <MenuItem onClick={() => setShowMenu(false)} component={<NavLink end activeClassName="active" to="/admin/orders/declined-orders" />}> Declined Orders</MenuItem>
                                <MenuItem onClick={() => setShowMenu(false)} component={<NavLink end activeClassName="active" to="/admin/orders/waiting-list" />}> Waiting List</MenuItem>
                                <MenuItem onClick={() => setShowMenu(false)} component={<NavLink end activeClassName="active" to="/admin/orders/cancelled-orders" />}> Cancelled Orders</MenuItem>
                            </SubMenu>
                            <SubMenu 
                                icon={<img src={scheduleIcon} alt="schedule" />} 
                                label="Scheduled Calls" 
                                open={openSubMenu === 'scheduleCalls'}
                                onClick={() => handleSubMenuToggle('scheduleCalls')}
                            >
                                <MenuItem onClick={() => setShowMenu(false)} component={<NavLink end activeClassName="active" to="/admin/appointments" />}> View Appointments</MenuItem>
                                <MenuItem onClick={() => setShowMenu(false)} component={<NavLink end activeClassName="active" to="/admin/set-availability" />}> Set Availability</MenuItem>
                            </SubMenu>
                            <MenuItem
                                onClick={() => {
                                    setShowMenu(false);
                                    localStorage.setItem('sidebarMenuState', 'false');
                                }}
                                icon={
                                    <NavLink end to="/admin/shipping" activeClassName="active">
                                        {({ isActive }) => (
                                            <img
                                                src={isActive ? shippingIconActive : shippingIcon}
                                                alt="shipping"
                                            />
                                        )}
                                    </NavLink>
                                }
                                component={<NavLink end activeClassName="active" to="/admin/shipping" />}> Shipping
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setShowMenu(false);
                                    localStorage.setItem('sidebarMenuState', 'false');
                                }}
                                icon={
                                    <NavLink end to="/admin/payment-methods" activeClassName="active">
                                        {({ isActive }) => (
                                            <img
                                                src={isActive ? paymentIconActive : paymentIcon}
                                                alt="payment"
                                            />
                                        )}
                                    </NavLink>
                                }
                                component={<NavLink end activeClassName="active" to="/admin/payment-methods" />}> Payment Methods
                            </MenuItem>
                        </Menu>
                    </Sidebar>
                </div>
            </OutsideClickHandler>
        </>
    );
}
